import {
  createStyles,
  Title,
  SimpleGrid,
  Text,
  Grid,
  Col,
  rem,
  Paper,
} from "@mantine/core";
import Shop from "../components/Shop/Shop";

const useStyles = createStyles((theme) => ({
  wrapper: {
    padding: `calc(${theme.spacing.xl} * 1) ${theme.spacing.xl}`,
    margin: `calc(${theme.spacing.xl} * 1) ${theme.spacing.xl}`,
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontSize: rem(36),
    fontWeight: 900,
    lineHeight: 1.1,
    marginBottom: theme.spacing.sm,
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
  },
}));

export function ShopPage() {
  const { classes } = useStyles();

  return (
    <div className={classes.wrapper}>
      <Grid gutter={20}>
        <Col span={12}>
          <Title className={classes.title}>SHOP</Title>
          <Text c="dimmed">
            We video all the work we carry out. View anytime and anywhere at
            your convenience!
          </Text>
        </Col>
        <Col span={12}>
          <SimpleGrid>
            <Paper p={20}>
              {/* <Product/> */}
              <Shop/>
            </Paper>
          </SimpleGrid>
        </Col>
      </Grid>
    </div>
  );
}
