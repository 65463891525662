import React, { useState } from "react";
import {
  createStyles,
  Text,
  Container,
  ActionIcon,
  Group,
  rem,
  Modal,
  Button,
  NumberInput,
} from "@mantine/core";
import {
  IconBrandTwitter,
  IconBrandYoutube,
  IconBrandInstagram,
} from "@tabler/icons-react";
import { ReactComponent as Logo } from "../assets/images/logo.svg";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { Link } from "react-router-dom";

const useStyles = createStyles((theme) => ({
  footer: {
    marginTop: rem(20),
    paddingTop: `calc(${theme.spacing.xl} * 2)`,
    paddingBottom: `calc(${theme.spacing.xl} * 2)`,
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.dark[6]
        : theme.colors.gray[0],
    borderTop: `${rem(1)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[2]
    }`,
  },

  logo: {
    maxWidth: rem(150),

    [theme.fn.smallerThan("sm")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  },

  description: {
    marginTop: rem(5),

    [theme.fn.smallerThan("sm")]: {
      marginTop: theme.spacing.xs,
      textAlign: "center",
    },
  },

  inner: {
    display: "flex",
    justifyContent: "space-between",

    [theme.fn.smallerThan("sm")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },

  groups: {
    display: "flex",
    flexWrap: "wrap",

    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },

  wrapper: {
    width: rem(160),
  },

  link: {
    display: "block",
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[1]
        : theme.colors.gray[6],
    fontSize: theme.fontSizes.sm,
    paddingTop: rem(3),
    paddingBottom: rem(3),

    "&:hover": {
      textDecoration: "underline",
    },
  },

  title: {
    fontSize: theme.fontSizes.lg,
    fontWeight: 700,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    marginBottom: `calc(${theme.spacing.xs} / 2)`,
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
  },

  afterFooter: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: theme.spacing.xl,
    paddingTop: theme.spacing.xl,
    paddingBottom: theme.spacing.xl,
    borderTop: `${rem(1)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[2]
    }`,

    [theme.fn.smallerThan("sm")]: {
      flexDirection: "column",
    },
  },

  social: {
    [theme.fn.smallerThan("sm")]: {
      marginTop: theme.spacing.xs,
    },
  },

  modalContent: {
    padding: theme.spacing.md,
  },

  modalFooter: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: theme.spacing.md,
  },
}));

export function Footer({ companyDetails }) {
  const { classes } = useStyles();
  const [donationAmount, setDonationAmount] = useState(0);
  const [showPayPalButtons, setShowPayPalButtons] = useState(false);
  const [email] = useState(companyDetails[0]?.email);
  const [phone] = useState(companyDetails[0]?.phone);

  const [isModalOpen, setModalOpen] = useState(false);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleConfirmAmount = () => {
    setDonationAmount(donationAmount);
    setShowPayPalButtons(true);
  };

  const handleCloseModal = () => {
    setDonationAmount(0);
    setModalOpen(false);
  };

  const handleChange = (value) => {
    setDonationAmount(value);
  };

  const mockData = {
    data: [
      {
        title: "LINKS",
        links: [
          { label: "Gallery", link: "/gallery" },
          { label: "Testimonials", link: "/testimonials" },
          { label: "Periodic Checks", link: "/periodicChecks" },
          { label: "Blogs", link: "/blogs" },
        ],
      },
      {
        title: "REFERENCE",
        links: [
          { label: "Login", link: "/login" },
          { label: "Register", link: "/register" },
          { label: "About Us", link: "/about" },
        ],
      },
      {
        title: "CONTACT US",
        links: [
          { label: `Phone: ${phone}` },
          { label: `Email: ${email}`}
        ],
      },
    ],
  };

  const groups = mockData?.data?.map((group) => {
    const links = group.links?.map((link, index) => (
      <Text
        key={index}
        className={classes.link}
        component={Link}
        to={link.link}
        onClick={() => {
          window.scrollTo({ top: 0, behavior: "smooth" });
        }}
      >
        {link.label}
      </Text>
    ));

    return (
      <div className={classes.wrapper} key={group.title}>
        <Text className={classes.title}>{group.title}</Text>
        {links}
      </div>
    );
  });

  return (
    <footer className={classes.footer}>
      <Container className={classes.inner}>
        <div className={classes.logo}>
          <Logo />
          <Text size="xs" color="dimmed" className={classes.description}>
            Professional Yacht Services Tailored to Your Needs.
          </Text>
        </div>
        <div className={classes.groups}>{groups}</div>
        <Button size="lg" onClick={handleOpenModal}>
          Donate-Paypal
        </Button>
      </Container>
      <Container className={classes.afterFooter}>
        <Text color="dimmed" size="sm">
          © 2023 Bonaventure.Service. All rights reserved.
        </Text>
        <Group spacing={0} className={classes.social} position="right" noWrap>
          <ActionIcon size="lg">
            <IconBrandTwitter size="1.05rem" stroke={1.5} />
          </ActionIcon>
          <ActionIcon size="lg">
            <IconBrandYoutube size="1.05rem" stroke={1.5} />
          </ActionIcon>
          <ActionIcon size="lg">
            <IconBrandInstagram size="1.05rem" stroke={1.5} />
          </ActionIcon>
        </Group>

        <Modal
          title="Donate Now!"
          opened={isModalOpen}
          onClose={handleCloseModal}
          size="md"
        >
          <div className={classes.modalContent}>
            <Text size="sm">Enter donation amount:</Text>
            <div style={{ display: "flex", alignItems: "center" }}>
              <NumberInput
                value={donationAmount}
                onChange={handleChange}
                style={{ marginRight: "8px" }}
              />
            </div>
            <Text size="md" color="dimmed">
              Total Amount: {donationAmount}$
            </Text>

            <>
              <PayPalScriptProvider
                options={{
                  "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
                  currency: process.env.REACT_APP_PAYPAL_CURRENCY,
                }}
              >
                <Button
                  m={4}
                  onClick={() =>
                    setDonationAmount(0) & setShowPayPalButtons(false)
                  }
                >
                  Reset
                </Button>
                {donationAmount > 0 ? (
                  <>
                    <Button m={4} onClick={handleConfirmAmount}>
                      Confirm Amount
                    </Button>
                    <Text size="sm">Choose a payment option:</Text>
                    {showPayPalButtons && (
                      <PayPalButtons
                        style={{ layout: "horizontal" }}
                        disabled={!donationAmount}
                        createOrder={(data, actions, error) => {
                          return actions.order.create({
                            intent: "CAPTURE",
                            currency: process.env.REACT_APP_PAYPAL_CURRENCY,
                            purchase_units: [
                              {
                                description: "This is the tip",
                                amount: {
                                  value: donationAmount,
                                },
                              },
                            ],
                          });
                        }}
                        onApprove={async (data, actions) => {
                          const order = await actions.order.capture();
                          console.log(order)
                          setDonationAmount(0);
                        }}
                        onError={(err) => {
                          console.log(err);
                        }}
                      />
                    )}
                  </>
                ) : null}
              </PayPalScriptProvider>
            </>
          </div>
          <div className={classes.modalFooter}>
            <Button onClick={handleCloseModal}>Close</Button>
          </div>
        </Modal>
      </Container>
    </footer>
  );
}
