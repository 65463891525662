import React, { useState, useEffect } from "react";
import Loader from "./components/Loader";
import { MantineProvider } from "@mantine/core";
import "./App.css";
import { Navbar } from "./components/Navbar";
import { Footer } from "./components/Footer";
import Home from "./pages/Home";
import { GetInTouch } from "./pages/Contact";
import { Login } from "./pages/Login";
import { Register } from "./pages/Register";
import { PeriodicChecks } from "./pages/PeriodicChecks";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ShopPage } from "./pages/Shop";
import { Videos } from "./pages/Videos";
import { Blogs } from "./pages/Blog";
import { About } from "./pages/AboutUs";
import { Testimonials } from "./pages/Testimonials";
import { Gallery } from "./pages/Gallery";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import BlogView from "./components/Blog/BlogView/BlogView";
import BlogForm from "./components/Forms/BlogForm";
import axios from "axios";
import Cartstate from './Contexts/Cartstate';
import Checkout from './components/Checkout/Checkout';


function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [companyDetails, setCompanyDetails] = useState(null);

  useEffect(() => {
    const fetchCompanyDetails = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/companyDetails/`
        );
        setCompanyDetails(response?.data.response.data);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching company details:", error);
        setIsLoading(false);
      }
    };

    fetchCompanyDetails();
  }, []);

  return (
    <div className="App">
      {isLoading ? (
        <Loader />
      ) : (
        <MantineProvider>
          <Cartstate>
            <Router>
              <Navbar />
              <Routes>
                <Route path="/" element={<Home />} />
                <Route
                  path="/contact"
                  element={<GetInTouch companyDetails={companyDetails} />}
                />
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/periodicChecks" element={<PeriodicChecks />} />
                <Route path="/shop" element={<ShopPage />} />
                <Route path="/videos" element={<Videos />} />
                <Route path="/blogs" element={<Blogs />} />
                <Route
                  path="/about"
                  element={<About companyDetails={companyDetails} />}
                />
                <Route path="/testimonials" element={<Testimonials />} />
                <Route path="/gallery" element={<Gallery />} />
                <Route path="/privacy" element={<PrivacyPolicy />} />
                <Route path="/blogs/:id" element={<BlogView />} />
                <Route path="/blogs/new" element={<BlogForm />} />
                <Route path='/checkout' element={<Checkout/>}/>
              </Routes>

              <Footer companyDetails={companyDetails} />
            </Router>
            </Cartstate>
        </MantineProvider>
      )}
    </div>
  );
}

export default App;
