import React, { useContext, useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {
  Title,
  Button,
  TextInput,
  Textarea,
  Col,
  Grid,
  Select,
} from "@mantine/core";
import * as Yup from "yup";
import axios from "axios";
import { AuthContext } from "../../hooks/AuthContext";

const validationSchema = Yup.object({
  title: Yup.string().required("Title is required"),
  description: Yup.string().required("Description is required"),
  thumbnail: Yup.string().required("Thumbnail is required"),
  category: Yup.string().required("Category is required"),
  body: Yup.string().required("Body is required"),
});

const demo = [
  { value: "category1", label: "Category 1" },
  { value: "category2", label: "Category 2" },
];

const initialValues = {
  title: "",
  thumbnail: "",
  category: "",
  body: "",
  description: "",
};

const BlogForm = () => {
  const { user } = useContext(AuthContext);
  const [categories, setCategories] = useState(demo);

  const handleSubmit = async (values, { resetForm }) => {
    try {
      const data = { ...values, user: user.id };
      const response = await axios.post(
        "http://localhost:5000/api/blog/",
        data
      );
      alert("Blog submitted successfully!");
      resetForm();
      console.log(response)
    } catch (error) {
      console.error("Error submitting blog:", error);
    }
  };

  const loadData = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/api/category`)
      .then((res) => {
        const categoriesData = res.data.response.data;
        const blogCategories = categoriesData.filter((category) => category.type === "blog");
        const categoryOptions = blogCategories.flatMap((category) => category.cats.map((cat) => ({
          value: cat.key,
          label: cat.display,
        })));
        setCategories(categoryOptions);
      })
      .catch((err) => console.log(err));
  };
  

  useEffect(() => {
    loadData();
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <Form encType="multipart/form-data">
        <Title
          size={50}
          weight={800}
          ta="center"
          mb="lg"
          mt="lg"
          variant="gradient"
          gradient={{ from: "#336699", to: "#736efe" }}
        >
          Write A Blog!
        </Title>
        <Grid gutter="xl" mr={20} ml={20}>
          <Col span={6}>
            <Field name="title">
              {({ field }) => <TextInput label="Title" {...field} required />}
            </Field>
            <ErrorMessage name="title" component="div" className="error" />
          </Col>
          <Col span={6}>
            <Field name="description">
              {({ field }) => (
                <TextInput label=" Short Description" {...field} required />
              )}
            </Field>
            <ErrorMessage
              name="description"
              component="div"
              className="error"
            />
          </Col>
          <Col span={6}>
            <Field name="thumbnail">
              {({ field }) => (
                <TextInput label="Thumbnail Url" {...field} required />
              )}
            </Field>
            <ErrorMessage name="thumbnail" component="div" className="error" />
          </Col>
          <Col span={6}>
            <Field name="category">
              {({ form, field }) => (
                <Select
                  id="category"
                  label="Category"
                  placeholder="Select a category"
                  data={categories}
                  {...field}
                  required
                  onChange={(value) => form.setFieldValue("category", value)}
                />
              )}
            </Field>
            <ErrorMessage name="category" component="div" className="error" />
          </Col>
          <Col span={12}>
            <Field name="body">
              {({ field }) => (
                <Textarea
                  label="Body"
                  placeholder="Enter blog content here"
                  required
                  {...field}
                />
              )}
            </Field>
            <ErrorMessage name="body" component="div" className="error" />
          </Col>
        </Grid>
        <Button
          mt={10}
          type="submit"
          variant="gradient"
          gradient={{ from: "#336699", to: "#736efe" }}
        >
          Submit
        </Button>
      </Form>
    </Formik>
  );
};

export default BlogForm;
