import React, { useContext } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import {
  TextInput,
  PasswordInput,
  Text,
  Paper,
  Group,
  Button,
  Divider,
  Anchor,
  Stack,
  Grid,
  Col,
} from "@mantine/core";
import RegisterUser from "../../assets/images/registerUser.jpg";
import axios from "axios";
import { AuthContext } from "../../hooks/AuthContext";

function useIsMobile() {
  const [isMobile, setIsMobile] = React.useState(false);

  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return isMobile;
}

export function RegistrationForm(props) {
  const isMobile = useIsMobile();
  const { dispatch } = useContext(AuthContext);

  const initialValues = {
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    password: "",
    confirm_password: "",
  };

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required("First name is required"),
    last_name: Yup.string().required("Last name is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    phone_number: Yup.string().required("Contact is required"),
    password: Yup.string()
      .min(6, "Password should include at least 6 characters")
      .required("Password is required"),
    confirm_password: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });

  const navigate = useNavigate();

  const handleSubmit = async (values) => {
    try {
      console.log(values);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/auth/register`,
        values
      );
      dispatch({ type: "LOGIN_SUCCESS", payload: response.data.data });
      alert("Registration successful!");

      // Redirect to homepage
      navigate("/");
    } catch (error) {
      alert("Registration failed. Please check your credentials.");
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <Grid gutter="lg" align="middle" justify={isMobile ? "center" : "stretch"}>
      {!isMobile && (
        <Col span={5}>
          <img
            src={RegisterUser}
            alt="Register"
            style={{ width: "100%", height: "auto" }}
          />
        </Col>
      )}

      <Col span={isMobile ? 12 : 6}>
        <Paper radius="xl" p="xl" m="xl" withBorder>
          <Text size={32} weight={800}>
            Welcome to Bonaventure Services, Register
          </Text>

          <Divider label="Continue with email" labelPosition="center" my="lg" />

          <form onSubmit={formik.handleSubmit}>
            <Stack>
              <TextInput
                required
                label="First Name"
                placeholder="John"
                name="first_name"
                value={formik.values.first_name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.first_name && formik.errors.first_name}
                radius="md"
              />

              <TextInput
                required
                label="Last Name"
                placeholder="Doe"
                name="last_name"
                value={formik.values.last_name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.last_name && formik.errors.last_name}
                radius="md"
              />

              <TextInput
                required
                label="Email"
                placeholder="hello@mantine.dev"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.email && formik.errors.email}
                radius="md"
              />

              <TextInput
                required
                label="Contact"
                placeholder="123-456-7890"
                name="phone_number"
                value={formik.values.phone_number}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.phone_number && formik.errors.phone_number}
                radius="md"
              />

              <PasswordInput
                required
                label="Password"
                placeholder="Your password"
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.password && formik.errors.password}
                radius="md"
              />

              <PasswordInput
                required
                label="Confirm Password"
                placeholder="Confirm your password"
                name="confirm_password"
                value={formik.values.confirm_password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.confirm_password &&
                  formik.errors.confirm_password
                }
                radius="md"
              />
            </Stack>

            <Group position="apart" mt="xl">
              <Link
                to="/login"
                onClick={() => {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
              >
                <Anchor
                  component="button"
                  type="button"
                  color="dimmed"
                  size="xs"
                >
                  Already have an account? Login
                </Anchor>
              </Link>
              <Button type="submit" radius="xl">
                Register
              </Button>
            </Group>
          </form>
        </Paper>
      </Col>
    </Grid>
  );
}
