import {
  createStyles,
  Container,
  Title,
  Text,
  Button,
  rem,
} from "@mantine/core";
import yatch_stock from "../assets/videos/yatch_stock.mp4";
import {  scroller } from "react-scroll";

const useStyles = createStyles((theme) => ({
  root: {
    paddingTop: `calc(${theme.spacing.xl} * 3)`,
    paddingBottom: `calc(${theme.spacing.xl} * 3)`,
    position: "relative",
    overflow: "hidden",
    
  },

  video: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    objectFit: "cover",
    zIndex: -1,
  },

  inner: {
    display: "flex",
    justifyContent: "space-between",

    [theme.fn.smallerThan("md")]: {
      flexDirection: "column",
    },
  },

  content: {
    paddingTop: `calc(${theme.spacing.xl} * 2)`,
    paddingBottom: `calc(${theme.spacing.xl} * 2)`,
    marginRight: `calc(${theme.spacing.xl} * 3)`,
    backdropFilter: "blur(1px)",

    [theme.fn.smallerThan("md")]: {
      marginRight: 0,
    },
  },

  title: {
    color: theme.white,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontWeight: 900,
    lineHeight: 1.05,
    maxWidth: rem(500),
    fontSize: rem(48),

    [theme.fn.smallerThan("md")]: {
      maxWidth: "100%",
      fontSize: rem(34),
      lineHeight: 1.15,
    },
  },

  description: {
    color: theme.white,
    opacity: 0.85,
    maxWidth: rem(500),

    [theme.fn.smallerThan("md")]: {
      maxWidth: "100%",
    },
  },

  control: {
    paddingLeft: rem(50),
    paddingRight: rem(50),
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontSize: rem(20),

    [theme.fn.smallerThan("md")]: {
      width: "100%",
    },
  },
}));

export function Hero() {
  const { classes } = useStyles();
  const scrollToForm = () => {
    scroller.scrollTo("bookNowForm", {
      smooth: true,
      offset: -50, 
    });
  };

  return (
    <div className={classes.root}>
      <video className={classes.video} autoPlay loop muted>
        <source src={yatch_stock} type="video/mp4" />
      </video>
      <Container size="lg">
        <div className={classes.inner}>
          <div className={classes.content}>
            <Title className={classes.title}>
              Services You Require{" "}
              <Text
                component="span"
                inherit
                variant="gradient"
                gradient={{ from: "#5efce8", to: "#736efe" }}
              >
                Complimented
              </Text>{" "}
              With Tagged Video
            </Title>

            <Text className={classes.description} mt={30}>
              Designed to revolutionize the boating experience by providing boat
              owners and enthusiasts with a comprehensive platform for boat
              management, video sharing, and community engagement
            </Text>

            <Button
              variant="gradient"
              gradient={{ from: "#5ECFEE", to: "#736efe" }}
              size="lg"
              className={classes.control}
              mt={40}
              onClick={scrollToForm}
            >
              Book Now!
            </Button>
          </div>
        </div>
      </Container>
    </div>
  );
}
