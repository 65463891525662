import {
  createStyles,
  Title,
  SimpleGrid,
  Text,
  Grid,
  Col,
  rem,
  Button,
} from "@mantine/core";
import BookNowForm from "./Forms/BookNowForm";
import { animateScroll as scroll } from 'react-scroll';


const useStyles = createStyles((theme) => ({
  wrapper: {
    padding: `calc(${theme.spacing.xl} * 2) ${theme.spacing.xl}`,
    margin: `calc(${theme.spacing.xl} * 2) ${theme.spacing.xl}`,
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontSize: rem(36),
    fontWeight: 900,
    lineHeight: 1.1,
    marginTop: 80,
    marginBottom: theme.spacing.md,
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
  },
}));

export function Interested() {
  const { classes } = useStyles();
  const scrollToTop = () => {
    scroll.scrollToTop({
      delay:200,
      duration: 1000, 
      smooth: true, 
    });
  };

  return (
    <div className={classes.wrapper}>
      <Grid gutter={100}>
        <Col span={12} md={5}>
          <Title className={classes.title} order={2}>
            Ready To Revolutionize Your Experience with Innovative Video Service
            of Work Done?
          </Title>
          <Text c="dimmed">
            Experience a Revolutionary Approach to Visual Documentation,
            Empowering You with Informed Decision-Making and Unmatched
            Satisfaction in Every Step of the Journey.
          </Text>
          <Button
              variant="gradient"
              gradient={{ from: "#5ECFEE", to: "#736efe" }}
              size="lg"
              className={classes.control}
              mt={40}
              onClick={scrollToTop}
            >
              Back To Top!
            </Button>
        </Col>
        <Col span={12} md={7}>
          <SimpleGrid id="bookNowForm">
            <BookNowForm/>
          </SimpleGrid>
        </Col>
      </Grid>
    </div>
  );
}
