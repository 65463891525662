import React from "react";

import { createRoot } from 'react-dom/client';
import { AuthContextProvider } from './hooks/AuthContext';

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./index.css";


createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <AuthContextProvider>
    <App />
    </AuthContextProvider>
  </React.StrictMode>
);

reportWebVitals();
