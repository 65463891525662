import React from 'react';
import { Card, Image, Text, Badge, createStyles, rem } from '@mantine/core';


const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor:
      theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    maxWidth: '400px',
    margin: '0 auto',
    boxShadow: theme.shadows.md,
    borderRadius: theme.radius.md,
  },
  imageSection: {
    position: 'relative',
    width: '100%',
    height: '400px',
    padding: theme.spacing.md,
    alignItems: 'center',
    justifyContent: 'center',
    borderBottom: `${rem(1)} solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },
  section: {
    padding: theme.spacing.md,
    borderTop: `${rem(1)} solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },
  saleBadge: {
    backgroundColor: theme.colors.red[6],
    color: theme.white,
    position: 'absolute',
    top: theme.spacing.xs,
    right: theme.spacing.xs,
    fontSize: theme.fontSizes.xs,
    padding: `${theme.spacing.xs}px ${theme.spacing.sm}px`,
  },
  productName: {
    fontWeight: 500,
  },
  productDescription: {
    marginTop: theme.spacing.md,
  },
}));

const ProCheckCard = ({ obj, id }) => {
  const classes = useStyles();

  return (
    <Card withBorder radius="md" className={classes.card} key={obj.id} mb={30}>
      <Card.Section className={classes.imageSection}>
        <Image src={obj.imageUrl} alt={obj.name} width={400} height={200} />
        {obj.sale && (
          <Badge variant="filled" className={classes.saleBadge}>
            Sale
          </Badge>
        )}
      </Card.Section>

      <Card.Section className={classes.section}>
        <Text className={classes.productName} size="xl" weight={600}>
          {obj.name}
        </Text>
        <Text size="sm" color="gray">
          {obj.category}
        </Text>
        <Text size="md" className={classes.productDescription}>
          {obj.description}
        </Text>
      </Card.Section>
    </Card>
  );
};

export default ProCheckCard;
