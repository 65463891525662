import { Interested } from "../components/Interested";
import { FeaturesCards } from "../components/Cards/FeaturesCard";
import { Hero } from "../components/Hero";
function Home() {
    return ( 
        <div>
        <Hero />
        <FeaturesCards />
        <Interested/>
        </div>

     );
}

export default Home;