import React, { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartPlus } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import Order from '../../../Contexts/Orders';
import { Button, Container, Image, Text } from '@mantine/core';

const ShopCard = ({ obj }) => {
  const router = useNavigate();
  const cart = useContext(Order);
  const addtoCart = () => {
    cart.setcart([
      ...cart.cart,
      {
        id: obj.id,
        name: obj.name,
        category: obj.category,
        imageUrl: obj.imageUrl,
        price: obj.price,
        amount: 1,
        index: cart.cart.length,
      },
    ]);
  };

  return (
    <Container
      className="border-2 border-gray-300 rounded-md m-2 p-4"
      style={{ maxWidth: 400 }}
    >
      <div className="relative">
        {obj.sale && (
          <div className="absolute top-0 left-0 bg-red-500 text-white px-2 py-1 rounded-tr-md rounded-bl-md">
            Sale!
          </div>
        )}
        <Image
          src={obj.imageUrl}
          onClick={() => router(`/shop/product/${obj.id}`)}
          alt="Couldn't load"
          fit="contain"
          className="cursor-pointer"
        />
      </div>
      <div className="mt-4">
        <Text size="lg" weight={700} className="mb-2">
          {obj.name}
        </Text>
        <Text size="lg" className="mb-2" color="teal">
          {obj.price}$
        </Text>
        <Text size="md" color="gray" className="mb-4">
          {obj.description.substr(0, 10)}...
        </Text>
        <Button
          variant="filled"
          color="teal"
          className="w-full"
          onClick={addtoCart}
        >
          Add to Cart <FontAwesomeIcon icon={faCartPlus} />
        </Button>
      </div>
    </Container>
  );
};

export default ShopCard;
