import React, { useState, useContext, useEffect } from "react";
import BlogCard from "./BlogCard/BlogCard";
import { Button,Grid,Col } from "@mantine/core";
import { Link } from "react-router-dom";
import { AuthContext } from "../../hooks/AuthContext";
import axios from 'axios';

const Blog = () => {
  const [blogs, setBlogs] = useState([]);

  const { user } = useContext(AuthContext);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/blog/`);
        setBlogs(response?.data.response.data);
      } catch (error) {
        console.error("Error fetching blogs:", error);
      }
    };

    fetchBlogs();
  }, []);

  return (
    <div className="bg-sky-100">
    {user && (
      <Link to="/blogs/new">
        <Button variant="outline" size="lg" mt={5} mb={10}>
          New Blog
        </Button>
      </Link>
    )}

    <Grid gutter="lg">
      {blogs.map((blog) => (
        <Col span={6} key={blog.id}>
          <BlogCard obj={{ ...blog, thumb: blog.thumbnail }} />
        </Col>
      ))}
    </Grid>
  </div>
  );
};

export default Blog;
