import React, { useState,useEffect } from "react";
import { Formik, Form, Field } from "formik";
import {
  Title,
  Button,
  TextInput,
  Col,
  Grid,
  Select,
  Checkbox,
  Textarea,
} from "@mantine/core";
import * as Yup from "yup";
import { countries } from "countries-list";
import axios from "axios";

const validationSchema = Yup.object({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  phoneNumber: Yup.string()
    .required("Phone number is required")
    .min(8, "Phone number must be at least 8 characters"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  streetAddress: Yup.string().required("Street Address is required"),
  addressLine2: Yup.string(),
  city: Yup.string().required("City is required"),
  postalCode: Yup.string().required("ZIP/Postal Code is required"),
  country: Yup.string().required("Country is required"),
  boatName: Yup.string().required("Boat Name is required"),
  boatManufacturer: Yup.string().required("Boat Manufacturer is required"),
  registryCountry: Yup.string().required("Country of Registry is required"),
  year: Yup.number()
    .required("Year is required")
    .min(1900, "Year must be greater than or equal to 1900")
    .max(new Date().getFullYear(), "Year cannot be in the future"),
  hullId: Yup.string().required("Hull ID is required"),
  engineManufacturer: Yup.string(),
  engineHours: Yup.number().positive("Engine Hours must be a positive number"),
  currentLocation: Yup.string().required("Current Location is required"),
  services: Yup.array()
    .of(Yup.string())
    .min(1, "At least one service must be selected"),
  serviceDetails: Yup.string().required("Service Details is required"),
});

const getYearOptions = () => {
  const currentYear = new Date().getFullYear();
  const startYear = 1900;
  const yearOptions = [];

  for (let year = currentYear; year >= startYear; year--) {
    yearOptions.push({ label: year.toString(), value: year.toString() });
  }

  return yearOptions;
};

// const servicesData = {
//   cleaning: [
//     { label: "Once", value: "CleaningOnce" },
//     { label: "Weekly", value: "CleaningWeekly" },
//     { label: "Monthly", value: "CleaningMonthly" },
//     { label: "Other", value: "CleaningOther" },
//   ],
//   repairs: [
//     { label: "Once", value: "RepairsOnce" },
//     { label: "Weekly", value: "RepairsWeekly" },
//     { label: "Monthly", value: "RepairsMonthly" },
//     { label: "Other", value: "RepairsOther" },
//   ],
//   miscellaneous: [
//     { label: "Once", value: "MiscellaneousOnce" },
//     { label: "Weekly", value: "MiscellaneousWeekly" },
//     { label: "Monthly", value: "MiscellaneousMonthly" },
//     { label: "Other", value: "MiscellaneousOther" },
//   ],
//   specialServices: [
//     { label: "Once", value: "SpecialServicesOnce" },
//     { label: "Weekly", value: "SpecialServicesWeekly" },
//     { label: "Monthly", value: "SpecialServicesMonthly" },
//     { label: "Other", value: "SpecialServicesOther" },
//   ],
// };

const initialValues = {
  firstName: "",
  lastName: "",
  phoneNumber: "",
  email: "",
  streetAddress: "",
  addressLine2: "",
  city: "",
  postalCode: "",
  country: "",
  boatName: "",
  boatManufacturer: "",
  registryCountry: "",
  year: "",
  hullId: "",
  engineManufacturer: "",
  engineHours: "",
  currentLocation: "",
  services: [],
  serviceDetails: "",
};

const PeriodicForm = () => {
  const [yearOptions] = useState(getYearOptions());
  const [countryOptions] = useState(
    Object.entries(countries).map(([countryCode, country]) => ({
      label: country.name,
      value: country.name,
    }))
  );

  const [servicesData, setServicesData] = useState({});

  useEffect(() => {
    const fetchServicesData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/services`
        );
        setServicesData(response.data.response.data[0].servicesData);
      } catch (error) {
        console.error(error);
      }
    };

    fetchServicesData();
  }, []);


  const handleSubmit = async (values, { resetForm }) => {
    try {
      const services = values.services.reduce((acc, service, index) => {
        acc[index] = service;
        return acc;
      }, {});
  
      const data = {
        ...values,
        services,
      };
  
  
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/periodicForm/`,
        data
      );
  
      alert("Form submitted successfully!");
      console.log(response.data.response.message);
      resetForm();
    } catch (error) {
      alert("Form submission failed!");
      console.error(error);
    }
  };
  
  
  

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <Form>
        <Title
          size={30}
          weight={800}
          ta="center"
          mb="lg"
          variant="gradient"
          gradient={{ from: "#336699", to: "#736efe" }}
        >
          Contact Information
        </Title>
        <Grid gutter="lg">
          <Col span={6}>
            <Field name="firstName">
              {({ field, form }) => (
                <TextInput
                  label="First Name"
                  id="firstName"
                  required
                  error={form.errors.firstName && form.touched.firstName}
                  {...field}
                />
              )}
            </Field>
          </Col>
          <Col span={6}>
            <Field name="lastName">
              {({ field, form }) => (
                <TextInput
                  label="Last Name"
                  id="lastName"
                  required
                  error={form.errors.lastName && form.touched.lastName}
                  {...field}
                />
              )}
            </Field>
          </Col>
          <Col span={6}>
            <Field name="phoneNumber">
              {({ field, form }) => (
                <TextInput
                  label="Phone Number"
                  id="phoneNumber"
                  required
                  error={form.errors.phoneNumber && form.touched.phoneNumber}
                  {...field}
                />
              )}
            </Field>
          </Col>
          <Col span={6}>
            <Field name="email">
              {({ field, form }) => (
                <TextInput
                  type="email"
                  label="Email"
                  id="email"
                  required
                  error={form.errors.email && form.touched.email}
                  {...field}
                />
              )}
            </Field>
          </Col>
          <Col span={12}>
            <Field name="streetAddress">
              {({ field, form }) => (
                <TextInput
                  label="Street Address"
                  id="streetAddress"
                  required
                  error={
                    form.errors.streetAddress && form.touched.streetAddress
                  }
                  {...field}
                />
              )}
            </Field>
          </Col>
          <Col span={12}>
            <Field name="addressLine2">
              {({ field }) => (
                <TextInput
                  label="Address Line 2 (optional)"
                  id="addressLine2"
                  {...field}
                />
              )}
            </Field>
          </Col>
          <Col span={6}>
            <Field name="city">
              {({ field, form }) => (
                <TextInput
                  label="City"
                  id="city"
                  required
                  error={form.errors.city && form.touched.city}
                  {...field}
                />
              )}
            </Field>
          </Col>
          <Col span={6}>
            <Field name="postalCode">
              {({ field, form }) => (
                <TextInput
                  label="ZIP/Postal Code"
                  id="postalCode"
                  required
                  error={form.errors.postalCode && form.touched.postalCode}
                  {...field}
                />
              )}
            </Field>
          </Col>
          <Col span={6}>
            <Field name="country">
              {({ field, form }) => (
                <Select
                  label="Country"
                  id="country"
                  required
                  error={form.errors.country && form.touched.country}
                  data={countryOptions}
                  {...field}
                  onChange={(value) => form.setFieldValue("country", value)}
                />
              )}
            </Field>
          </Col>
        </Grid>
        <Title
          size={30}
          weight={800}
          ta="center"
          mt="lg"
          mb="lg"
          variant="gradient"
          gradient={{ from: "#336699", to: "#736efe" }}
        >
          Boat Information
        </Title>
        <Grid gutter="lg">
          <Col span={6}>
            <Field name="boatName">
              {({ field, form }) => (
                <TextInput
                  label="Boat Name"
                  id="boatName"
                  required
                  error={form.errors.boatName && form.touched.boatName}
                  {...field}
                />
              )}
            </Field>
          </Col>
          <Col span={6}>
            <Field name="boatManufacturer">
              {({ field, form }) => (
                <TextInput
                  label="Boat Manufacturer"
                  id="boatManufacturer"
                  required
                  error={
                    form.errors.boatManufacturer &&
                    form.touched.boatManufacturer
                  }
                  {...field}
                />
              )}
            </Field>
          </Col>
          <Col span={6}>
            <Field name="registryCountry">
              {({ field, form }) => (
                <Select
                  label="Country of Registry"
                  id="registryCountry"
                  required
                  error={
                    form.errors.registryCountry && form.touched.registryCountry
                  }
                  data={countryOptions}
                  {...field}
                  onChange={(value) =>
                    form.setFieldValue("registryCountry", value)
                  }
                />
              )}
            </Field>
          </Col>
          <Col span={6}>
            <Field name="year">
              {({ field, form }) => (
                <Select
                  label="Year"
                  id="year"
                  required
                  error={form.errors.year && form.touched.year}
                  data={yearOptions}
                  {...field}
                  onChange={(value) => form.setFieldValue("year", value)}
                />
              )}
            </Field>
          </Col>

          <Col span={6}>
            <Field name="hullId">
              {({ field, form }) => (
                <TextInput
                  label="Hull ID"
                  id="hullId"
                  required
                  error={form.errors.hullId && form.touched.hullId}
                  {...field}
                />
              )}
            </Field>
          </Col>
          <Col span={6}>
            <Field name="engineManufacturer">
              {({ field }) => (
                <TextInput
                  label="Engine Manufacturer (optional)"
                  id="engineManufacturer"
                  {...field}
                />
              )}
            </Field>
          </Col>
          <Col span={6}>
            <Field name="engineHours">
              {({ field, form }) => (
                <TextInput
                  type="number"
                  label="Engine Hours (optional)"
                  id="engineHours"
                  error={form.errors.engineHours && form.touched.engineHours}
                  {...field}
                />
              )}
            </Field>
          </Col>
          <Col span={12}>
            <Field name="currentLocation">
              {({ field, form }) => (
                <TextInput
                  label="Current Location"
                  id="currentLocation"
                  required
                  error={
                    form.errors.currentLocation && form.touched.currentLocation
                  }
                  {...field}
                />
              )}
            </Field>
          </Col>
        </Grid>
        <Title
          size={30}
          weight={800}
          ta="center"
          mt="lg"
          mb="lg"
          variant="gradient"
          gradient={{ from: "#336699", to: "#736efe" }}
        >
          Service Information
        </Title>
        <Grid gutter="lg">
          <Col span={12}>
            <Col span={12}>
              <Field name="services">
                {({ field, form }) => (
                  <Grid gutter="lg" columns={2}>
                    <Title order={4} mr={20}>
                      Services:{" "}
                    </Title>
                    {Object.keys(servicesData).map((serviceGroup) => (
                      <React.Fragment key={serviceGroup}>
                        <Col span={2}>
                          <Checkbox
                          size="lg"
                            key={serviceGroup}
                            label={serviceGroup}
                            value={serviceGroup}
                            checked={field.value.includes(serviceGroup)}
                            onChange={(event) => {
                              const isChecked = event.currentTarget.checked;
                              if (isChecked) {
                                const newServices = [
                                  ...field.value,
                                  serviceGroup,
                                ];
                                form.setFieldValue("services", newServices);
                              } else {
                                const newServices = field.value.filter(
                                  (value) => value !== serviceGroup
                                );
                                form.setFieldValue("services", newServices);
                              }
                            }}
                          />
                        </Col>
                        {field.value.includes(serviceGroup) && (
                          <React.Fragment>
                            {servicesData[serviceGroup].map((service) => (
                              <Checkbox size="sm"
                                m={15}
                                key={service.value}
                                label={service.label}
                                value={service.value}
                                checked={field.value.includes(service.value)}
                                onChange={(event) => {
                                  const isChecked = event.currentTarget.checked;
                                  if (isChecked) {
                                    const newServices = [
                                      ...field.value,
                                      service.value,
                                    ];
                                    form.setFieldValue("services", newServices);
                                  } else {
                                    const newServices = field.value.filter(
                                      (value) => value !== service.value
                                    );
                                    form.setFieldValue("services", newServices);
                                  }
                                }}
                              />
                            ))}
                          </React.Fragment>
                        )}
                      </React.Fragment>
                    ))}
                  </Grid>
                )}
              </Field>
            </Col>
          </Col>

          <Col span={12}>
            <Field name="serviceDetails">
              {({ field, form }) => (
                <Col span={12}>
                  <Textarea
                    label="Service Details"
                    id="serviceDetails"
                    required
                    error={
                      form.errors.serviceDetails && form.touched.serviceDetails
                    }
                    {...field}
                    fullwidth="true"
                  />
                </Col>
              )}
            </Field>
          </Col>
        </Grid>
        <Button type="submit" mt="xl">
          Submit
        </Button>
      </Form>
    </Formik>
  );
};

export default PeriodicForm;
