import {
  createStyles,
  Title,
  SimpleGrid,
  Text,
  Grid,
  Col,
  rem,
  Paper,
} from "@mantine/core";
import CardsCarousel from "../components/Cards/ReviewsCard";
import TestimonialForm from "../components/Forms/TestimonialForm";

const useStyles = createStyles((theme) => ({
  wrapper: {
    padding: `calc(${theme.spacing.xl} * 1) ${theme.spacing.xl}`,
    margin: `calc(${theme.spacing.xl} * 1) ${theme.spacing.xl}`,
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontSize: rem(36),
    fontWeight: 900,
    lineHeight: 1.1,
    marginBottom: theme.spacing.sm,
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
  },
}));

export function Testimonials() {
  const { classes } = useStyles();

  return (
    <div className={classes.wrapper}>
      <Grid gutter={20}>
        <Col span={12}>
          <Title className={classes.title}>CLIENT'S TESTIMONIALS</Title>
          <Text>
            Take a moment to explore and see what our clients have to say about
            their experiences with us. We take pride in our commitment to
            excellence, and we're confident that our clients' words will
            highlight the quality of our work and the satisfaction of our
            partners.
          </Text>
        </Col>
        <Col span={12}>
          <SimpleGrid>
            <Paper withBorder p={10} m={5}>
              <CardsCarousel/>
            </Paper>
          </SimpleGrid>
        </Col>
        <Col span={12}>
          <Title className={classes.title}>Worked With Us?</Title>
          <Text>
            If you've already worked with us and would like to share your
            thoughts, we offer a convenient way to write and submit your review.
            We value your input and appreciate your contribution to our growing
            collection of testimonials.
          </Text>
        </Col>
        <Col span={12}>
          <SimpleGrid>
            <Paper withBorder p={20}>
              <TestimonialForm/>
            </Paper>
          </SimpleGrid>
        </Col>
      </Grid>
    </div>
  );
}
