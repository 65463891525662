import React from "react";
import { Formik, Form, Field } from "formik";
import { Title, Button, TextInput, Textarea, Col, Grid } from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { IconCalendar } from "@tabler/icons-react";
import * as Yup from "yup";
import { useState } from "react";
import axios from "axios";

const validationSchema = Yup.object({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  phoneNumber: Yup.string()
    .required("Phone number is required")
    .min(8, "Phone number must be at least 8 characters"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  serviceRequired: Yup.string()
    .required("Service description is required")
    .max(300, "Service description must be less than 300 words"),
  dateOfService: Yup.date().required("Date of service is required"),
});

const BookNowForm = () => {
  const [dateOfService, setDateOfService] = useState(null);

  const initialValues = {
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    serviceRequired: "",
    dateOfService: dateOfService,
  };

  const handleSubmit = async (values,{ resetForm }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/bookNow/`,
        values
      );
      alert("Form submitted successfully!");
      console.log(response.data.response.message);
      resetForm();
      setDateOfService(null)
    } catch (error) {
      alert("Form submission failed!");

      console.error(error);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <Form>
        <Title
          size={50}
          weight={800}
          ta="center"
          mb="lg"
          variant="gradient"
          gradient={{ from: "#336699", to: "#736efe" }}
        >
          Book Service Now!
        </Title>
        <Grid gutter="lg">
          <Col span={6}>
            <Field name="firstName">
              {({ field, form }) => (
                <TextInput
                  label="First Name"
                  id="firstName"
                  required
                  error={form.errors.firstName && form.touched.firstName}
                  {...field}
                />
              )}
            </Field>
          </Col>
          <Col span={6}>
            <Field name="lastName">
              {({ field, form }) => (
                <TextInput
                  label="Last Name"
                  id="lastName"
                  required
                  error={form.errors.lastName && form.touched.lastName}
                  {...field}
                />
              )}
            </Field>
          </Col>
          <Col span={6}>
            <Field name="phoneNumber">
              {({ field, form }) => (
                <TextInput
                  label="Phone Number"
                  id="phoneNumber"
                  required
                  error={form.errors.phoneNumber && form.touched.phoneNumber}
                  {...field}
                />
              )}
            </Field>
          </Col>
          <Col span={6}>
            <Field name="email">
              {({ field, form }) => (
                <TextInput
                  label="Email"
                  id="email"
                  type="email"
                  required
                  error={form.errors.email && form.touched.email}
                  {...field}
                />
              )}
            </Field>
          </Col>
          <Col span={12}>
            <Field name="serviceRequired">
              {({ field, form }) => (
                <Textarea
                  label="Service Required (Brief Description)"
                  id="serviceRequired"
                  required
                  error={
                    form.errors.serviceRequired && form.touched.serviceRequired
                  }
                  {...field}
                />
              )}
            </Field>
          </Col>
          <Col span={12}>
            <Field name="dateOfService">
              {({ field, form }) => (
                <DatePickerInput
                  icon={<IconCalendar size="1.1rem" stroke={1.5} />}
                  required
                  clearable
                  label="Pick date"
                  placeholder="Pick date"
                  value={dateOfService}
                  onChange={(value) => {
                    setDateOfService(value);
                    form.setFieldValue("dateOfService", value);
                  }}
                />
              )}
            </Field>
          </Col>
          <Col span={12}>
            <Button
              fullWidth
              type="submit"
              variant="gradient"
              gradient={{ from: "#5ECFEE", to: "#736efe" }}
              size="lg"
              mt={4}
            >
              Book Now!
            </Button>
          </Col>
        </Grid>
      </Form>
    </Formik>
  );
};

export default BookNowForm;
