import React, { useState, useEffect } from 'react';
import { Carousel } from '@mantine/carousel';
import { Paper, Text, Title, createStyles, rem } from '@mantine/core';
import axios from 'axios';

const useStyles = createStyles((theme) => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing.lg,
    margin: theme.spacing.lg,
    height: 300,
    background: theme.colors.blue[0],
    borderRadius: theme.radius.lg,
    boxShadow: theme.shadows.md,
  },

  role: {
    marginBottom: theme.spacing.md,
    color: theme.colors.gray[6],
  },

  content: {
    textAlign: 'center',
  },
}));

function ReviewCard({ firstName, company, testimonial }) {
  const { classes } = useStyles();
  return (
    <Paper shadow="md" padding="sm" className={classes.card}>
      <Title>{firstName}</Title>
      <Text size="xs" className={classes.role}>
        {company}
      </Text>
      <Text className={classes.content}>{testimonial}</Text>
    </Paper>
  );
}

function ReviewCarousel() {
  const [testimonials, setTestimonials] = useState([]);

  useEffect(() => {
    const fetchTestimonials = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/testimonialForm/`);
        const filteredTestimonials = response.data.response.message.filter((testimonial) => testimonial.status);
        setTestimonials(filteredTestimonials);
      } catch (error) {
        console.error('Error fetching testimonials:', error);
        setTestimonials([]);
      }
    };

    fetchTestimonials();
  }, []);

  const demoTestimonials = [
    {
      firstName: 'Brandon P.',
      company: 'Chief Marketing Officer',
      testimonial:
        'It really saves me time and effort. It is exactly what our business has been lacking. EEZY is the most valuable business resource we have EVER purchased. After using EEZY my business skyrocketed!',
    },
    {
      firstName: 'Krysta B.',
      company: 'Entrepreneur',
      testimonial:
        "I didn't even need training. We've used EEZY for the last five years. I have gotten at least 50 times the value from EEZY. I made back the purchase price in just 48 hours!",
    },
    {
      firstName: 'Darcy L.',
      company: 'Movie star',
      testimonial:
        "Thank you for making it painless, pleasant and most of all, hassle free! I'm good to go. No matter where you go, EEZY is the coolest, most happening thing around! I love EEZY!",
    },
  ];

  if (testimonials.length === 0) {
    return (
      <Carousel
        slideSize="50%"
        breakpoints={[{ maxWidth: 'sm', slideSize: '100%', slideGap: rem(2) }]}
        slideGap="xl"
        align="start"
        slidesToScroll={1}
        loop
        controlSize={40}
      >
        {demoTestimonials.map((testimonial, index) => (
          <Carousel.Slide key={index}>
            <ReviewCard {...testimonial} />
          </Carousel.Slide>
        ))}
      </Carousel>
    );
  }

  return (
    <Carousel
      slideSize="50%"
      breakpoints={[{ maxWidth: 'sm', slideSize: '100%', slideGap: rem(2) }]}
      slideGap="xl"
      align="start"
      slidesToScroll={1}
      loop
      controlSize={40}
    >
      {testimonials.map((testimonial, index) => (
        <Carousel.Slide key={index}>
          <ReviewCard {...testimonial} />
        </Carousel.Slide>
      ))}
    </Carousel>
  );
}

export default ReviewCarousel;
