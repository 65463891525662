import {
    createStyles,
    Title,
    SimpleGrid,
    Text,
    Grid,
    Col,
    rem,
    Paper,
  } from "@mantine/core";
import { GalleryCardsGrid } from "../components/Cards/GalleryCard";
  
  
  const useStyles = createStyles((theme) => ({
    wrapper: {
      padding: `calc(${theme.spacing.xl} * 1) ${theme.spacing.xl}`,
      margin: `calc(${theme.spacing.xl} * 1) ${theme.spacing.xl}`,
    },
  
    title: {
      fontFamily: `Greycliff CF, ${theme.fontFamily}`,
      fontSize: rem(36),
      fontWeight: 900,
      lineHeight: 1.1,
      marginBottom: theme.spacing.sm,
      color: theme.colorScheme === "dark" ? theme.white : theme.black,
    },
  }));
  
  export function Gallery() {
    const { classes } = useStyles();
  
    return (
      <div className={classes.wrapper}>
        <Grid gutter={20}>
          <Col span={12}>
            <Title className={classes.title} >
            GALLERY
            </Title>
            <Text c="dimmed">
            We video all the work we carry out. View anytime and anywhere at your convenience!
            </Text>

          </Col>
          <Col span={12}>
            <SimpleGrid >
                <Paper p={10}> 
                <GalleryCardsGrid/>
                </Paper>
            </SimpleGrid>
          </Col>
        </Grid>
      </div>
    );
  }
  