import {
  createStyles,
  Header,
  HoverCard,
  Group,
  Button,
  UnstyledButton,
  Text,
  SimpleGrid,
  ThemeIcon,
  Anchor,
  Divider,
  Center,
  Box,
  Burger,
  Drawer,
  Collapse,
  ScrollArea,
  rem,
} from "@mantine/core";
import { ReactComponent as Logo } from "../assets/images/logo.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartShopping } from "@fortawesome/free-solid-svg-icons";
import Cart from "./Cart/Cart";
import Order from "../Contexts/Orders";
import { useDisclosure } from "@mantine/hooks";
import {
  IconUsers,
  IconThumbUp,
  IconPhotoHeart,
  IconArticle,
  IconChevronDown,
} from "@tabler/icons-react";
import { Link } from "react-router-dom";
import React, { useState, useContext } from "react";
import { AuthContext } from "../hooks/AuthContext";

const useStyles = createStyles((theme) => ({
  link: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    paddingLeft: theme.spacing.md,
    paddingRight: theme.spacing.md,
    textDecoration: "none",
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    fontWeight: 600,
    fontSize: theme.fontSizes.md,

    [theme.fn.smallerThan("sm")]: {
      height: rem(42),
      display: "flex",
      alignItems: "center",
      width: "100%",
    },

    ...theme.fn.hover({
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
    }),
  },

  subLink: {
    width: "100%",
    padding: `${theme.spacing.xs} ${theme.spacing.md}`,
    borderRadius: theme.radius.md,

    ...theme.fn.hover({
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[7]
          : theme.colors.gray[0],
    }),

    "&:active": theme.activeStyles,
  },

  dropdownFooter: {
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.dark[7]
        : theme.colors.gray[0],
    margin: `calc(${theme.spacing.md} * -1)`,
    marginTop: theme.spacing.sm,
    padding: `${theme.spacing.md} calc(${theme.spacing.md} * 2)`,
    paddingBottom: theme.spacing.xl,
    borderTop: `${rem(1)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[1]
    }`,
  },

  hiddenMobile: {
    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },

  hiddenDesktop: {
    [theme.fn.largerThan("sm")]: {
      display: "none",
    },
  },
}));

const linkdata = [
  {
    icon: IconUsers,
    title: "About Us",
    description: "Learn more about our mission",
    url: "/about",
  },
  {
    icon: IconThumbUp,
    title: "Testimonials",
    description: "Read what our customers say about us",
    url: "/testimonials",
  },
  {
    icon: IconPhotoHeart,
    title: "Gallery",
    description: "Explore our beautiful photo gallery",
    url: "/gallery",
  },
  {
    icon: IconArticle,
    title: "Blogs",
    description: "Discover insightful blog posts",
    url: "/blogs",
  },
];
export function Navbar() {
  const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] =
    useDisclosure(false);
  const { user, dispatch } = useContext(AuthContext);
  const [linksOpened, { toggle: toggleLinks }] = useDisclosure(false);
  const { classes, theme } = useStyles();
  const [open, setopen] = useState(false);
  const [cartOpen, setCartOpen] = useState(false);
  const cartc = useContext(Order);

  const handleCartToggle = () => {
    setCartOpen(!cartOpen);
  };

  const handleLogout = () => {
    dispatch({ type: "LOGOUT" });
  };

  const links = linkdata.map((item) => (
    <Link to={item.url} key={item.title}>
      <UnstyledButton className={classes.subLink} key={item.title}>
        <Group noWrap align="flex-start">
          <ThemeIcon size={34} variant="default" radius="md">
            <item.icon size={rem(22)} color={theme.fn.primaryColor()} />
          </ThemeIcon>
          <div>
            <Text size="sm" fw={500}>
              {item.title}
            </Text>
            <Text size="xs" color="dimmed">
              {item.description}
            </Text>
          </div>
        </Group>
      </UnstyledButton>
    </Link>
  ));

  return (
    <>
      <Box>
        <Header height={95} px="md">
          <Group position="apart" sx={{ height: "100%" }}>
            <Link to="/">
              <Logo width={100} height={85} />
            </Link>

            <Group
              sx={{ height: "100%" }}
              spacing={0}
              className={classes.hiddenMobile}
            >
              <Link to="/" className={classes.link}>
                Home
              </Link>
              <HoverCard
                width={600}
                position="bottom"
                radius="md"
                shadow="md"
                withinPortal
              >
                <HoverCard.Target>
                  <Anchor className={classes.link}>
                    <Center inline>
                      <Box component="span" mr={5}>
                        About
                      </Box>
                      <IconChevronDown
                        size={16}
                        color={theme.fn.primaryColor()}
                      />
                    </Center>
                  </Anchor>
                </HoverCard.Target>

                <HoverCard.Dropdown sx={{ overflow: "hidden" }}>
                  <Group position="apart" px="md">
                    <Text fw={500}>Know More</Text>
                  </Group>

                  <Divider
                    my="sm"
                    mx="-md"
                    color={theme.colorScheme === "dark" ? "dark.5" : "gray.1"}
                  />

                  <SimpleGrid cols={2} spacing={0}>
                    {links}
                  </SimpleGrid>

                  <div className={classes.dropdownFooter}>
                    <Group position="apart">
                      <div>
                        <Text fw={500} fz="sm">
                          Get started
                        </Text>
                        <Text size="xs" color="dimmed">
                          We video all the work we carry out. View anytime at
                          your convenience!
                        </Text>
                      </div>
                      <Link to={"./booking"}>
                        <Button variant="default">Book Now!</Button>
                      </Link>
                    </Group>
                  </div>
                </HoverCard.Dropdown>
              </HoverCard>
              <Link to="/periodicChecks" className={classes.link}>
                Periodic Checks
              </Link>
              <Link to="/shop" className={classes.link}>
                Shop
              </Link>
              <Link to="/videos" className={classes.link}>
                Videos
              </Link>
              <Link to="/contact" className={classes.link}>
                Contact
              </Link>
            </Group>

            <Group className={classes.hiddenMobile}>
              {user ? (
                <>
                  <Text>Welcome, {user.first_name}!</Text>
                  {user && <Button onClick={handleLogout}>Logout</Button>}
                </>
              ) : (
                <>
                  <Link to="/login">
                    <Button variant="default">Log in</Button>
                  </Link>
                  <Link to="/register">
                    <Button>Sign up</Button>
                  </Link>
                </>
              )}

              <Button
                className="cart"
                onClick={() => {
                  setopen(!open);
                  handleCartToggle();
                }}
              >
                {" "}
                {cartc.cart.length > 0 ? (
                  <span>{cartc.cart.length}</span>
                ) : null}{" "}
                <FontAwesomeIcon
                  icon={faCartShopping}
                  className="text-xl mx-3 transition-all duration-300 hover:text-blue-900"
                />
              </Button>

              <Drawer
                opened={cartOpen}
                position="right" // Set the position to "left"
                onClose={handleCartToggle} // Close the drawer when clicked outside
              >
                <Cart open={cartOpen} setopen={setCartOpen} />{" "}
                {/* Render your cart component inside the drawer */}
              </Drawer>
            </Group>

            <Burger
              opened={drawerOpened}
              onClick={toggleDrawer}
              className={classes.hiddenDesktop}
            />
          </Group>
        </Header>

        <Drawer
          opened={drawerOpened}
          onClose={closeDrawer}
          size="100%"
          padding="md"
          title="Bonaventure Services"
          className={classes.hiddenDesktop}
          zIndex={1000000}
        >
          <ScrollArea h={`calc(100vh - ${rem(60)})`} mx="-md">
            <Divider
              my="sm"
              color={theme.colorScheme === "dark" ? "dark.5" : "gray.1"}
            />

            <Link to="/" className={classes.link}>
              Home
            </Link>
            <UnstyledButton className={classes.link} onClick={toggleLinks}>
              <Center inline>
                <Box component="span" mr={5}>
                  About Us
                </Box>
                <IconChevronDown size={16} color={theme.fn.primaryColor()} />
              </Center>
            </UnstyledButton>
            <Collapse in={linksOpened}>{links}</Collapse>
            <Link to="/periodicChecks" className={classes.link}>
              Periodic Checks
            </Link>
            <Link to="/shop" className={classes.link}>
              Shop
            </Link>
            <Link to="/videos" className={classes.link}>
              Videos
            </Link>
            <Link to="/contact" className={classes.link}>
              Contact Us
            </Link>

            <Divider
              my="sm"
              color={theme.colorScheme === "dark" ? "dark.5" : "gray.1"}
            />

            <Group position="center" grow pb="xl" px="md">
              <Link to="/login">
                <Button variant="default">Log in</Button>
              </Link>
              <Link to="/register">
                <Button>Sign up</Button>
              </Link>
            </Group>
          </ScrollArea>
        </Drawer>
      </Box>
    </>
  );
}
