import {
  createStyles,
  Badge,
  Group,
  Title,
  Text,
  Card,
  SimpleGrid,
  Container,
  rem,
} from "@mantine/core";
import { IconGauge, IconRefresh,IconVideo,IconSubtask,IconTools,IconSpray} from "@tabler/icons-react";

const mockdata = [
    {
      title: "Walkthrough Video",
      description:
        "Ability to view the current state of your vessel. Helps in making informed decisions about maintenance, repairs or re-commissioning your vessel for your arrival. Customized packages are available also for Charter Companies for handover briefings.",
      icon: IconVideo,
    },
    {
      title: "Maintenance",
      description:
        "Keeping your boat well-maintained will extend its life and give you and your family many more years of enjoyment. Let us know what's required and you can leave it up to us to get it done.",
      icon: IconTools,
    },
    {
      title: "Management",
      description:
        "Together, we will create a customized list for your yacht while you are away. Your customized list will be backed by videos where you can view them at your convenience. Please note that in St. Lucia, if you intend to leave us with your yacht, a Temporary Importation form, available from the Marina Office, must be filed with Customs and Immigration.",
      icon: IconSubtask,
    },
    {
      title: "Concierge Services",
      description:
        "Your appointed shore-based crew for your yacht. Acting as your agent for customs clearing, a clearinghouse for charter guests, provisioning, reservations, securing contractors, to name a few. Receive first-class service we offer as your shore-side support to any of your requests.",
      icon: IconGauge,
    },
    {
      title: "Spray and Cleaning Yacht",
      description:
        "Pristine maintenance and refreshing cleaning for your yacht. Our professional team will ensure your yacht stays in impeccable condition, ready for your next adventure.",
      icon: IconSpray,
    },
    {
      title: "Stay Uptodate",
      description:
        "Stay informed with timely information and updates about your yacht. We keep you in the loop with the latest developments, ensuring you have all the necessary details at your fingertips.",
      icon: IconRefresh,
    },
  ];

const useStyles = createStyles((theme) => ({
  title: {
    fontSize: rem(34),
    fontWeight: 900,

    [theme.fn.smallerThan("sm")]: {
      fontSize: rem(24),
    },
  },

  description: {
    maxWidth: 600,
    margin: "auto",

    "&::after": {
      content: '""',
      display: "block",
      backgroundColor: theme.fn.primaryColor(),
      width: rem(45),
      height: rem(2),
      marginTop: theme.spacing.sm,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },

  card: {
    border: `${rem(1)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[1]
    }`,
  },

  cardTitle: {
    "&::after": {
      content: '""',
      display: "block",
      backgroundColor: theme.fn.primaryColor(),
      width: rem(45),
      height: rem(2),
      marginTop: theme.spacing.sm,
    },
  },
}));

export function FeaturesCards() {
  const { classes, theme } = useStyles();
  const features = mockdata.map((feature) => (
    <Card
      key={feature.title}
      shadow="md"
      radius="md"
      className={classes.card}
      padding="xl"
    >
      <feature.icon size={rem(50)} stroke={2} color={theme.fn.primaryColor()} />
      <Text fz="lg" fw={500} className={classes.cardTitle} mt="md">
        {feature.title}
      </Text>
      <Text fz="sm" c="dimmed" mt="sm">
        {feature.description}
      </Text>
    </Card>
  ));

  return (
    <Container size="lg" py="xl">
      <Group position="center">
        <Badge variant="filled" size="lg">
          Best Yatch Services
        </Badge>
      </Group>

      <Title order={2} className={classes.title} ta="center" mt="sm">
        Professional Yacht Services Tailored to Your Needs
      </Title>

      <Text c="dimmed" className={classes.description} ta="center" mt="md">
        Unparalleled Expertise, Meticulous Care, and Personalized Solutions for
        Comprehensive Yacht Management and Maintenance
      </Text>

      <SimpleGrid
        cols={3}
        spacing="xl"
        mt={50}
        breakpoints={[{ maxWidth: "md", cols: 1 }]}
      >
        {features}
      </SimpleGrid>
    </Container>
  );
}
