import React, { useContext, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faShoppingBag } from '@fortawesome/free-solid-svg-icons';
import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js';
import { Input, Button, Text, Notification, Card } from '@mantine/core';
import { v4 as uuid4 } from 'uuid';
import axios from 'axios';
import Order from '../../Contexts/Orders';
import ProCheckCard from './ProCheckCard/ProCheckCard';

const Checkout = () => {
  const cart = useContext(Order);
  const [first, setFirst] = useState('');
  const [last, setLast] = useState('');
  const [address, setAddress] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [city, setCity] = useState('');
  const [status, setStatus] = useState(false);
  const [paypalName, setPaypalName] = useState('');
  const [paypalEmail, setPaypalEmail] = useState('');
  const [paypalAddress, setPaypalAddress] = useState('');
  const [paypalID, setPaypalID] = useState('random');
  const [done, setDone] = useState(false);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    setTotal(0);
    cart.cart.map((v, i) => {
      setTotal((p) => p + parseInt(v.price));
    });
  }, []);

  const placeOrder = async (method) => {
    let date = new Date();

    await axios
      .post(`${process.env.REACT_APP_API_URL}/orders`, {
        Headers: {
          'Content-type': 'application/json',
        },
        fullName: `${first} ${last}`,
        email: email,
        phone: phone,
        address: address,
        city: city,
        method,
        products: cart.cart,
        status,
        total,
        paypalName,
        paypalEmail,
        paypalAddress,
        time: `${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`,
        paypalID,
        orderID: uuid4(),
      })
      .then((res) => {
        console.log(res.data);
        setFirst('');
        setLast('');
        setEmail('');
        setPhone('');
        setAddress('');
        setCity('');
        setStatus(false);
        setTotal('');
        cart.setCart([]);
        setPaypalName('');
        setPaypalEmail('');
        setPaypalAddress('');
        setPaypalID('');
        setDone(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (status) {
      placeOrder('PayPal').then(() => setStatus(false));
    }
  }, [status]);

  return (
    <div>
      {done ? (
        <div className="fixed top-0 left-0 right-0 bottom-0 bg-black bg-opacity-30 flex items-center justify-center">
          <Card shadow="md" padding="lg" radius="md" className="m-3">
            <center>
              <FontAwesomeIcon icon={faCheckCircle} className="text-green-600" fontSize={100} />
              <Text size="lg" className="my-3">
                We have received your order. Please check your Mail Inbox
              </Text>
              <Button
                className="bg-theme py-2 px-5 rounded-md shadow-sm shadow-black text-white"
                onClick={(e) => setDone(false)}
              >
                Okay
              </Button>
            </center>
          </Card>
        </div>
      ) : null}
      <Text className="uppercase text-center text-black text-5xl my-3 font-bold">checkout</Text>
      <div className="grid grid-cols-1 md:grid-cols-3">
        <div className="col-span-2">
          <Text className="col-span-2 text-center text-lg uppercase">
            Fill The form to place order
          </Text>
          <div className="grid grid-cols-1 md:grid-cols-2">
            <div className="p-2">
              <Input
                type="text"
                placeholder="First Name"
                radius="md"
                value={first}
                onChange={(e) => setFirst(e.target.value)}
              />
            </div>
            <div className="p-2">
              <Input
                type="text"
                placeholder="Last Name"
                radius="md"
                value={last}
                onChange={(e) => setLast(e.target.value)}
              />
            </div>

            <div className="p-2">
              <Input
                type="email"
                placeholder="E-mail"
                radius="md"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="p-2">
              <Input
                type="tel"
                placeholder="Phone Number"
                radius="md"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
            <div className="p-2">
              <Input
                type="text"
                placeholder="Address"
                radius="md"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </div>
            <div className="p-2">
              <Input
                type="text"
                placeholder="City"
                radius="md"
                value={city}
                onChange={(e) => setCity(e.target.value)}
              />
            </div>
            <div>
              <div>
                <Text className="text-xl ml-2 mt-5">
                  Total : <span className="text-rose-600">{total}$</span>
                </Text>
              </div>
              <Text className="p-2 text-gray-600">Payment Method:</Text>
            </div>
            <div></div>
            <div className="p-1">
              <Button
                className="rounded-md bg-theme shadow-sm shadow-black px-5 py-2 text-white w-full disabled:bg-gray-600"
                disabled={
                  !first ||
                  !last ||
                  !email.includes('@') ||
                  !phone ||
                  !city ||
                  !address ||
                  total < 1
                }
                onClick={(e) => placeOrder('COD')}
              >
                <FontAwesomeIcon icon={faShoppingBag} color="white" /> Cash on Delivery
              </Button>
            </div>
            <div className="p-1">
              <PayPalScriptProvider
                options={{ 'client-id': 'AUckiHkQHQv6Hc3Faq5LyeijgPTw10-per7oKOc2Pz8UR03KkHWlJpa24o_E1rTVC02TmtqAy7lDSbeP', currency: 'USD' }}
              >
                <PayPalButtons
                  style={{ layout: 'horizontal' }}
                  disabled={
                    !first ||
                    !last ||
                    !email.includes('@') ||
                    !phone ||
                    !address ||
                    !city ||
                    total < 1
                  }
                  createOrder={(data, actions, error) => {
                    return actions.order.create({
                      intent: 'CAPTURE',
                      purchase_units: [
                        {
                          description: '',
                          amount: {
                            value: total,
                          },
                        },
                      ],
                    });
                  }}
                  onApprove={async (data, actions) => {
                    const order = await actions.order.capture();
                    console.log(order);
                    setPaypalID(order.payer.payer_id);
                    setPaypalName(`${order.payer.name.given_name} ${order.payer.name.surname}`);
                    setPaypalEmail(order.payer.email_address);
                    setStatus(true);
                  }}
                  onError={(err) => {
                    console.log(err);
                  }}
                />
              </PayPalScriptProvider>
            </div>
          </div>
        </div>

        <div className="col-span-1">
          <Text className="text-center text-lg">Ordered Products</Text>
          {cart.cart.length < 1 ? (
            <div>
              <Text className="text-center text-gray-600">No product added</Text>
            </div>
          ) : (
            cart.cart.map((v, i) => {
              return <ProCheckCard obj={v} key={i} id={i} />;
            })
          )}
          <div>
            <Text className="text-xl text-right mr-2 mt-5">
              Total : <span className="text-rose-600">{total}$</span>
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
