import React from "react";
import {
  Paper,
  Text,
  TextInput,
  Textarea,
  Button,
  Group,
  Grid,
  Col,
  createStyles,
  rem,
  Title,
  Select,
} from "@mantine/core";
import { useFormik } from "formik";
import bg from "../assets/images/bg.svg";
import { ContactIconsList } from "../components/ContactIcons.tsx";
import axios from "axios";

const useStyles = createStyles((theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    margin: 10,
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[8] : theme.white,
    borderRadius: theme.radius.lg,
    padding: rem(4),
    border: `${rem(1)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[8] : theme.colors.gray[2]
    }`,
    [theme.breakpoints.sm]: {
      flexDirection: "row",
    },
  },
  contacts: {
    boxSizing: "border-box",
    position: "relative",
    borderRadius: theme.radius.lg,
    backgroundImage: `url(${bg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    border: `${rem(1)} solid transparent`,
    padding: theme.spacing.xl,
    marginBottom: theme.spacing.sm,
    marginLeft: 20,
  },
  form: {
    boxSizing: "border-box",
    padding: theme.spacing.xl,
    borderLeft: 0,
  },
  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontSize: rem(36),
    fontWeight: 900,
    lineHeight: 1.1,
    marginTop: 43,
    marginBottom: theme.spacing.sm,
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
  },
}));

export function GetInTouch({companyDetails}) {
  const { classes } = useStyles();

  const handleSubmit = async (values, { resetForm }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/contactForm`,
        values
      );
      alert("Form submitted successfully!");
      console.log(response.data.response.message);
      resetForm();
    } catch (error) {
      alert("Form submission failed!");
      console.error(error);
    }
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      subject: "",
      message: "",
      category: "",
    },
    onSubmit: handleSubmit
  });

  return (
    <Paper shadow="md" radius="lg">
      <Title className={classes.title}>CONTACT US</Title>
      <Grid gutter="lg" justify="flex-start">
        <Col span={12} sm={6} lg={4}>
          <div className={classes.contacts}>
            <Text fz="lg" m={20} fw={700} className={classes.title} c="#fff">
              Contact information
            </Text>
            <ContactIconsList companyDetails={companyDetails} variant="light" />
          </div>
        </Col>
        <Col span={12} sm={6} lg={8}>
          <form className={classes.form} onSubmit={formik.handleSubmit}>
            <Text fz="lg" fw={700} className={classes.title}>
              Get in touch
            </Text>

            <div className={classes.fields}>
              <Grid gutter="md">
                <Col span={12} md={6}>
                  <TextInput
                    label="Your name"
                    placeholder="Your name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    name="name"
                  />
                </Col>
                <Col span={12} md={6}>
                  <TextInput
                    label="Your email"
                    placeholder="hello@bonaventure.info"
                    required
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    name="email"
                  />
                </Col>
                <Col span={12} md={6}>
                  <TextInput
                    mt="md"
                    label="Subject"
                    placeholder="Subject"
                    required
                    value={formik.values.subject}
                    onChange={formik.handleChange}
                    name="subject"
                  />
                </Col>
                <Col span={12} md={6}>
                  <Select
                    mt="md"
                    label="Category"
                    placeholder="Select a category"
                    required
                    value={formik.values.category}
                    onChange={(value) =>
                      formik.setFieldValue("category", value)
                    }
                    name="category"
                    data={[
                      { value: "support", label: "Support" },
                      { value: "service", label: "Service" },
                      { value: "query/request", label: "Query/Request" },
                      { value: "other", label: "Other" },
                    ]}
                  />
                </Col>
              </Grid>

              <Textarea
                mt="md"
                label="Your message"
                placeholder="Please include all relevant information"
                minRows={3}
                value={formik.values.message}
                onChange={formik.handleChange}
                name="message"
              />

              <Group position="right" mt="md">
                <Button type="submit">Send message</Button>
              </Group>
            </div>
          </form>
        </Col>
      </Grid>
      <div>
        <iframe
          title="Bonaventure Location"
          width="100%"
          height="600"
          frameBorder="0"
          loading="lazy"
          marginHeight="0"
          marginWidth="0"
          src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Rodney%20Bay%20Marina+(Bonaventure%20Services)&amp;t=p&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
        >
          <a href="https://www.maps.ie/distance-area-calculator.html">
            measure acres/hectares on map
          </a>
        </iframe>
      </div>
    </Paper>
  );
}
