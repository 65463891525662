import React, { useState } from 'react';
import { Card, Text, Button, Image } from '@mantine/core';
import { Link } from 'react-router-dom';
import logo from "../../../assets/images/logo.png";

const BlogCard = ({ obj }) => {
  const [thumbnailError, setThumbnailError] = useState(false);

  const handleThumbnailError = () => {
    setThumbnailError(true);
  };

  const Thumbnail = !thumbnailError && obj.thumbnail ? (
    <Image
      src={obj.thumbnail}
      alt="Blog Image"
      height={300}
      fit="cover"
      onError={handleThumbnailError}
    />
  ) : (
    <img src={logo} alt="Logo" height={300} />
  );

  return (
    <Card shadow="md" m="md" padding="sm" radius="md">
      {Thumbnail}

      <div style={{ marginTop: '1rem' }}>
        <Text size="xl" weight={700} style={{ marginBottom: '0.5rem' }}>
          {obj.title}
        </Text>
        <Text size="sm" color="gray" style={{ marginBottom: '1rem' }}>
          {obj.description.substr(0, 17)}....
        </Text>
        <Text size="sm" color="gray">
          Uploaded On: {new Date(obj.createdAt).toLocaleDateString()}
        </Text>
        <Link to={`/blogs/${obj.id}`}>
          <Button variant="filled" style={{ marginTop: '1rem' }}>
            Read Blog
          </Button>
        </Link>
      </div>
    </Card>
  );
};

export default BlogCard;
