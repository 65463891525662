import React, { useState, useEffect, useContext } from "react";
import { Button, Container } from "@mantine/core";
import CommentAdapter from "../BlogView/CommentAdapter/CommentAdapter";
import { useParams } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../../hooks/AuthContext";

const BlogView = () => {
  const { id } = useParams();
  const [blog, setBlog] = useState({ comments: [] });
  const [myComment, setMyComment] = useState("");
  const { user } = useContext(AuthContext);
  const [myUser] = useState(user?.first_name);

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/blog/${id}`
        );
        setBlog(response?.data.blog);
      } catch (error) {
        console.error("Error fetching blog:", error);
      }
    };

    fetchBlog();
  }, [id]);

  const handleCommentChange = (event) => {
    setMyComment(event.target.value);
  };

  const handlePostComment = () => {
    if (myComment) {
      const newComment = {
        id: blog.comments.length + 1,
        name: myUser,
        comment: myComment,
        date: new Date().toISOString().split("T")[0],
      };
      setBlog((prevBlog) => ({
        ...prevBlog,
        comments: [...prevBlog.comments, newComment],
      }));
      setMyComment("");
    }
  };

  if (!blog) {
    return <p>Blog not found</p>;
  }

  return (
    <Container>
      <h1>Title: {blog.title}</h1>
      <h3>Description: {blog.description}</h3>
      <p>{blog.body}</p>

      <div className="mt-5">
        <h1 className="m-2 text-black text-2xl">Comments</h1>
        <div className="p-3">
          <p className="my-1 text-gray-600">Comment as: {myUser}</p>
          <textarea
            inputMode="text"
            placeholder="Your comment here..."
            className="w-full p-2 rounded-md"
            value={myComment}
            onChange={handleCommentChange}
            rows={3}
          />
          <center>
            <Button onClick={handlePostComment} disabled={!myComment}>
              Post comment
            </Button>
          </center>
        </div>

        {blog.comments && blog.comments.length > 0 ? (
          blog.comments.map((comment) => (
            <CommentAdapter key={comment.id} obj={comment} />
          ))
        ) : (
          <p className="text-center text-gray-600">No comments to show</p>
        )}
      </div>
    </Container>
  );
};

export default BlogView;
