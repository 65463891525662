import React, { useState, useEffect, Fragment } from "react";
import axios from "axios";
import {
  createStyles,
  SimpleGrid,
  Card,
  Image,
  Text,
  Container,
  AspectRatio,
  Modal,
} from "@mantine/core";

const useStyles = createStyles((theme) => ({
  card: {
    transition: "transform 150ms ease, box-shadow 150ms ease",
    background: theme.colors.blue[0],

    "&:hover": {
      transform: "scale(1.01)",
      boxShadow: theme.shadows.md,
    },
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontWeight: 600,
  },

  subtitle: {
    marginTop: theme.spacing.xs,
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[3]
        : theme.colors.gray[6],
  },
}));

export function GalleryCardsGrid() {
  const { classes } = useStyles();
  const [selectedArticle, setSelectedArticle] = useState(null);
  const [galleryData, setGalleryData] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchGalleryData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/gallery/`
        );
        setGalleryData(response.data.response.data);
      } catch (error) {
        console.error("Error fetching gallery data:", error);
        setError("Failed to fetch gallery data");
      }
    };

    fetchGalleryData();
  }, []);

  const handleCardClick = (article) => {
    setSelectedArticle(article);
  };

  const handleCloseModal = () => {
    setSelectedArticle(null);
  };

  const cards = galleryData?.map((article) => (
    <Card
      key={article.title}
      p="md"
      radius="lg"
      className={classes.card}
      onClick={() => handleCardClick(article)}
    >
      <AspectRatio ratio={1920 / 1080}>
        <Image src={article.thumbnailUrl} />
      </AspectRatio>
      <Text className={classes.title} mt={5}>
        {article.title}
      </Text>
      <Text className={classes.subtitle} mt={3}>
        {article.subtitle}
      </Text>
    </Card>
  ));

  return (
    <Container py="lg">
      {error ? (
        <Text color="red">{error}</Text>
      ) : (
        <>
          <SimpleGrid cols={2} breakpoints={[{ maxWidth: "md", cols: 1 }]}>
            {cards}
          </SimpleGrid>
          <Modal
            opened={!!selectedArticle}
            onClose={handleCloseModal}
            title={selectedArticle?.title}
          >
            {selectedArticle && (
              <Container p="lg">
                {selectedArticle?.elements.map((element, index) => (
                  <Fragment key={index}>
                    <Text className={classes.subtitle} mt={3}>
                      {element.caption}
                    </Text>
                    <Image
                      key={element.title}
                      src={element.image}
                      alt={element.title}
                      height={300}
                      fit="contain"
                    />
                  </Fragment>
                ))}
              </Container>
            )}
          </Modal>
        </>
      )}
    </Container>
  );
}
