import { useState } from "react";import {
  createStyles,
  Title,
  SimpleGrid,
  Text,
  Grid,
  Col,
  rem,
  Paper,
  Button,
} from "@mantine/core";
import { Link } from "react-router-dom";
import Stats from "../components/Stats.tsx";


const useStyles = createStyles((theme) => ({
  wrapper: {
    padding: `calc(${theme.spacing.xl} * 1) ${theme.spacing.xl}`,
    margin: `calc(${theme.spacing.xl} * 1) ${theme.spacing.xl}`,
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontSize: rem(36),
    fontWeight: 900,
    lineHeight: 1.1,
    marginBottom: theme.spacing.sm,
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing.lg,
    padding: theme.spacing.lg,
  },
}));




export function About({companyDetails}) {

  const data = [
    {
      title: "Users",
      stats: companyDetails[0]?.users,
      description:
        "24% more than in the same month last year, 33% more than two years ago",
    },
    {
      title: "Testimonials",
      stats: companyDetails[0]?.testimonials,
      description:
        "13% less compared to last month, new user engagement up by 6%",
    },
    {
      title: "Projects Done",
      stats: companyDetails[0]?.projectsDone,
      description: "1994 orders were completed this month, 97% satisfaction rate",
    },
    {
      title: "Charters",
      stats: companyDetails[0]?.charters,
      description:
        "24% more than in the same month last year, 33% more than two years ago",
    },
  ];

  const { classes } = useStyles();
  const [aboutText, setAboutText] = useState("We video all the work we perform on your behalf. Giving you the ability to view work done, anytime, anywhere. Our approach keeps you in control of your project and treat you as a partner. No project is too big or too small. We craft our reports with videos, photos, showing before we begin, during the project, and upon completion. This forms an easy timeline for you to follow. We cater to the owner who is away, the passerby and the live aboard residents. Make better and more words");
  if (companyDetails[0]?.aboutUs !== aboutText){
    setAboutText(companyDetails[0]?.aboutUs)
  }
  
  return (
    <div className={classes.wrapper}>
      <Grid gutter={20}>
        <Col span={12}>
          <Title className={classes.title}>ABOUT US</Title>
          <Text>{aboutText}</Text>
          <Text mt={20}>
            Fill out the <Link to="/periodicChecks">Periodic Checks</Link> Form
            to create custom packages. Within 48 hours, you will surely get a
            response. We provide daily, weekly, monthly, or yearly reports,
            based on the length of the project or package plan.
          </Text>
          <div className={classes.buttonContainer}>
            <Button mr={10} component={Link} to="/periodicChecks">
              Periodic Checks
            </Button>
            <Button ml={10} component={Link} to="/shop">
              Make A Purchase
            </Button>
          </div>
        </Col>
        <Col span={12}>
          <SimpleGrid>
            <Paper withBorder p={30}>
              <Stats data={data} />
            </Paper>
          </SimpleGrid>
        </Col>
        <Col span={12}>
          <Text>
            Bonaventure Yacht Services prides itself in offering a full spectrum
            of services to help boat owners and individuals and companies.
          </Text>
          <div className={classes.buttonContainer}>
            <Button
              mr={10}
              component={Link}
              to="/videos"
              onClick={() => {
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}
            >
              Example
            </Button>
          </div>
        </Col>
      </Grid>
    </div>
  );
}
