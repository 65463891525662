import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FaTimes } from 'react-icons/fa';
import { Button, Text, Paper } from '@mantine/core';

import Order from '../../Contexts/Orders';
import Cartmini from './Cartmini/Cartmini';

const Cart = ({ open, setopen }) => {
  useEffect(() => {
    if (open) {
      setMargin('right-0');
    } else {
      setMargin('-right-80');
    }
  }, [open]);

  const cart = useContext(Order);
  const [margin, setMargin] = useState('right-0');

  return (
    <Paper
      className={`fixed h-full w-auto bg-white transition-all duration-300 ease-in-out p-3 rounded-tl-lg rounded-bl-lg top-0 bottom-0 ${margin}`}
      style={{ zIndex: 9999999999999999999999999999999999 }}
    >
      <div className="flex items-center">
        <Text size="xl" className="flex-1 mr-5">
          Cart
        </Text>
      </div>
      <div
        className={`h-full flex flex-col ${
          Object.values(cart.cart).length < 1 ? 'justify-center items-center' : 'justify-start items-start'
        }`}
      >
        {Object.values(cart.cart).length < 1 ? (
          <div>
            <p className="text-gray-400">No Items to show</p>
          </div>
        ) : (
          <div className="overflow-y-scroll noscroll">
            {cart.cart.map((v, i) => (
              <Cartmini obj={v} id={i} key={i} />
            ))}
            <div className="flex fixed bottom-2">
              <Button
                color="white"
                variant="outline"
                radius="md"
                shadow="sm"
                className="flex-2 m-1 shadow-neutral-800"
                onClick={(e) => cart.setcart([])}
              >
                Clear Cart
              </Button>
              <Link to="/checkout">
                <Button
                  color="white"
                  background="theme"
                  radius="md"
                  shadow="sm"
                  className="flex-2 m-1 shadow-neutral-800"
                >
                  Checkout
                </Button>
              </Link>
            </div>
          </div>
        )}
      </div>
    </Paper>
  );
};

export default Cart;
