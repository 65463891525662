import axios from 'axios';
import React, { useEffect, useState } from 'react';
import ShopCard from './ShopCard/ShopCard';

const Shop = () => {
  const [mode, setmode] = useState(0);
  const [data, setdata] = useState([]);
  const [cats, setcats] = useState(['product']);

  const loadData = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/product`)
      .then((res) => {
        console.log(res.data.response.data);
        setdata(res.data.response.data);
        const temp = [];
        data.forEach((v) => {
          if (!temp.includes(v.category)) {
            temp.push(v.category);
          }
        });
        setcats(temp);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div>
      <div className='w-full bg-theme flex items-center justify-center'>
        {cats?.map((v, i) => (
          <button
            className={`p-3 rounded-lg border-2 ${
              mode === i ? 'text-theme bg-white' : 'text-white border-white'
            } my-3 mx-1`}
            onClick={() => setmode(i)}
            key={i}
          >
            {v}
          </button>
        ))}
      </div>
      <div className='my-3 p-2 grid md:grid-cols-6 grid-cols-2 sm:grid-cols-2 xl:grid-cols-6'>
        {data?.map((v, i) => {
          if (v.cat === cats[mode]) {
            return <ShopCard obj={v} key={i} />;
          }
          return null; // or provide a fallback UI element
        })}
      </div>
    </div>
  );
};

export default Shop;
