import React, { useState, useRef, useEffect } from "react";
import {
  SimpleGrid,
  Card,
  Image,
  Text,
  Container,
  AspectRatio,
  Button,
  Modal,
  Input,
  Group,
} from "@mantine/core";
import ReactPlayer from "react-player";

export function VideoCardGrid({ mockdata }) {
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const playerRef = useRef(null);
  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 767);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleVideoOpen = (video) => {
    setSelectedVideo(video);
  };

  const handleVideoClose = () => {
    setSelectedVideo(null);
  };

  const handleSearch = (value) => {
    setSearchTerm(value);
  };

  const formatTimestamp = (seconds) => {
    const date = new Date(null);
    date.setSeconds(seconds);
    return date.toISOString().substr(14, 5);
  };

  const filteredVideos = mockdata.filter(
    (video) =>
      video.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      video.description.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const cards = filteredVideos.map((video) => (
    <Card
      key={video.id}
      p="md"
      radius="lg"
      style={{
        transition: "transform 150ms ease, box-shadow 150ms ease",
        backgroundColor: "#f9f9f9",
        ":hover": {
          transform: "scale(1.01)",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        },
      }}
      onClick={() => handleVideoOpen(video)}
    >
      <AspectRatio ratio={16 / 9}>
        <Image src={video.thumbnail} />
      </AspectRatio>
      <Text
        style={{
          fontFamily: "Greycliff CF, sans-serif",
          fontWeight: 600,
        }}
        mt={5}
      >
        {video.title}
      </Text>
      <Text
        style={{
          marginTop: "4px",
          color: "#666",
        }}
        mt={3}
      >
        {video.description}
      </Text>
      <Button
        variant="outline"
        color="blue"
        fullWidth
        mt={3}
        onClick={() => handleVideoOpen(video)}
      >
        Watch Video
      </Button>
    </Card>
  ));

  return (
    <Container py="lg">
      <Input
        value={searchTerm}
        onChange={(event) => handleSearch(event.target.value)}
        placeholder="Search videos..."
        style={{
          width: "100%",
          marginBottom: "16px",
        }}
      />

      <SimpleGrid cols={2} breakpoints={[{ maxWidth: "md", cols: 1 }]}>
        {cards}
      </SimpleGrid>

      <Modal
        title={selectedVideo ? selectedVideo.title : ""}
        opened={selectedVideo !== null}
        onClose={handleVideoClose}
        hideclosebutton="true"
        fullScreen
        transitionProps={{
          transition: "fade",
          duration: 800,
          timingFunction: "linear",
        }}
        overlayProps={{
          color: "#333",
          opacity: 0.55,
          backdropFilter: "blur(2px)",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: isMobileView ? "column" : "row",
          }}
        >
          <div
            style={{
              width: isMobileView ? "100%" : "75%",
              position: "relative",
            }}
          >
            <ReactPlayer
              ref={playerRef}
              url={selectedVideo ? selectedVideo.link : ""}
              controls
              width="100%"
              height={isMobileView ? "20vh" : "80vh"}
            />
          </div>

          {selectedVideo !== null && (
            <div
              style={{
                width: isMobileView ? "100%" : "25%",
                padding: "16px",
              }}
            >
              <Text
                style={{
                  fontSize: "18px",
                  fontWeight: 600,
                  marginBottom: "16px",
                }}
              >
                Chapters
              </Text>
              <Group direction="column" spacing="md">
                {selectedVideo.chapters?.map((chapter, index) => (
                  <Button
                    key={index}
                    style={{
                      display: "block",
                      marginBottom: "16px",
                    }}
                    variant="light"
                    color="blue"
                    fullWidth
                    onClick={() =>
                      playerRef.current.seekTo(chapter.start, "seconds")
                    }
                  >
                    {`${index + 1}. ${chapter.title} (${formatTimestamp(
                      chapter.start
                    )})`}
                  </Button>
                ))}
              </Group>
            </div>
          )}
        </div>
      </Modal>
    </Container>
  );
}
