import React from 'react';
import { Paper, Text } from '@mantine/core';

const CommentAdapter = ({ obj }) => {
  console.log(obj)
  return (
    <Paper padding="lg" style={{ borderBottom: '2px solid #ccc', marginBottom: '1rem' }}>
      <Text size="lg" color="blue">
        {obj.name}
      </Text>
      <Text color="gray" style={{ marginTop: '0.5rem' }}>
        {obj.comment}
      </Text>
      <Text align="right" color="gray" style={{ marginTop: '0.5rem' }}>
        Commented on: {obj.date}
      </Text>
    </Paper>
  );
};

export default CommentAdapter;
