import axios from "axios";
import React, { useContext } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";

import {
  TextInput,
  PasswordInput,
  Text,
  Paper,
  Group,
  Button,
  Divider,
  Anchor,
  Stack,
  Grid,
  Col,
} from "@mantine/core";
import LoginUser from "../../assets/images/loginUser.jpg";
import { AuthContext } from "../../hooks/AuthContext";

function useIsMobile() {
  const [isMobile, setIsMobile] = React.useState(false);

  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return isMobile;
}

export function LoginForm(props) {
  const isMobile = useIsMobile();
  const { dispatch } = useContext(AuthContext);

  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is required"),
    password: Yup.string()
      .min(6, "Password should include at least 6 characters")
      .required("Password is required"),
  });

  const navigate = useNavigate();

  const handleSubmit = async (values) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/auth/login`,
        values
      );
      dispatch({ type: "LOGIN_SUCCESS", payload: response.data.data });
      alert("Login successful!");
  
      // Redirect to homepage
      navigate("/");
    } catch (error) {
      alert("Login failed. Please check your credentials.");
    }
  };
  
  
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <Grid gutter="lg" align="middle" justify={isMobile ? "center" : "stretch"}>
      {!isMobile && (
        <Col span={5}>
          <img
            src={LoginUser}
            alt="Login"
            style={{ width: "100%", height: "auto" }}
          />
        </Col>
      )}

      <Col span={isMobile ? 12 : 6}>
        <Paper radius="xl" p="xl" m="xl" withBorder>
          <Text size={32} weight={800}>
            Welcome to Bonaventure Services, Login
          </Text>

          <Divider label="Continue with email" labelPosition="center" my="lg" />

          <form onSubmit={formik.handleSubmit}>
            <Stack>
              <TextInput
                required
                label="Email"
                placeholder="hello@mantine.dev"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.email && formik.errors.email}
                radius="md"
              />

              <PasswordInput
                required
                label="Password"
                placeholder="Your password"
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.password && formik.errors.password}
                radius="md"
              />
            </Stack>

            <Group position="apart" mt="xl">
              <Link
                to="/register"
                onClick={() => {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
              >
                <Anchor
                  component="button"
                  type="button"
                  color="dimmed"
                  size="xs"
                >
                  "Don't have an account? Register"
                </Anchor>
              </Link>
              <Button type="submit" radius="xl">
                Login
              </Button>
            </Group>
          </form>
        </Paper>
      </Col>
    </Grid>
  );
}
