import React from "react";
import { Formik, Form, Field } from "formik";
import { Title, Button, TextInput, Textarea, Col, Grid } from "@mantine/core";
import * as Yup from "yup";
import axios from "axios";

const validationSchema = Yup.object({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  company: Yup.string().required("Company/Boat is required"),
  testimonial: Yup.string().required("Testimonial is required"),
});

const TestimonialForm = () => {
  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    company: "",
    testimonial: "",
  };

  const handleSubmit = async (values,{ resetForm }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/testimonialForm/`,
        values
      );
      alert("Testimonial Form submitted successfully!");
      console.log(response.data.response.data);
      resetForm();
    } catch (error) {
      alert("Testimonial submission failed!");

      console.error(error);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <Form>
        <Title
          size={40}
          weight={800}
          ta="center"
          mb="lg"
          variant="gradient"
          gradient={{ from: "#336699", to: "#736efe" }}
        >
          Testimonial Form
        </Title>
        <Grid gutter="lg">
          <Col span={6}>
            <Field name="firstName">
              {({ field, form }) => (
                <TextInput
                  label="First Name"
                  id="firstName"
                  required
                  error={form.errors.firstName && form.touched.firstName}
                  {...field}
                />
              )}
            </Field>
          </Col>
          <Col span={6}>
            <Field name="lastName">
              {({ field, form }) => (
                <TextInput
                  label="Last Name"
                  id="lastName"
                  required
                  error={form.errors.lastName && form.touched.lastName}
                  {...field}
                />
              )}
            </Field>
          </Col>
          <Col span={6}>
            <Field name="email">
              {({ field, form }) => (
                <TextInput
                  label="Email"
                  id="email"
                  type="email"
                  required
                  error={form.errors.email && form.touched.email}
                  {...field}
                />
              )}
            </Field>
          </Col>
          <Col span={6}>
            <Field name="company">
              {({ field, form }) => (
                <TextInput
                  label="Company/Boat"
                  id="company"
                  required
                  error={form.errors.company && form.touched.company}
                  {...field}
                />
              )}
            </Field>
          </Col>
          <Col span={12}>
            <Field name="testimonial">
              {({ field, form }) => (
                <Textarea
                  label="Testimonial"
                  id="testimonial"
                  required
                  error={form.errors.testimonial && form.touched.testimonial}
                  {...field}
                />
              )}
            </Field>
          </Col>
          <Col span={12}>
            <Button        
              type="submit"
              variant="gradient"
              gradient={{ from: "#5ECFEE", to: "#736efe" }}
              size="lg"
              mt={4}
            >
              Submit
            </Button>
          </Col>
        </Grid>
      </Form>
    </Formik>
  );
};

export default TestimonialForm;
