import React, { useContext, useEffect, useState } from 'react';
import { Image, Button, createStyles, useMantineTheme } from '@mantine/core';
import { FaTimes } from 'react-icons/fa';
import Order from '../../../Contexts/Orders';
import axios from 'axios';

const Cartmini = ({ obj, id }) => {
  const [amount, setAmount] = useState(obj.amount);
  const [price, setPrice] = useState(parseInt(obj.price));
  const cart = useContext(Order);
  const [initPrice, setInitPrice] = useState(0);
  const theme = useMantineTheme();

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}products/${obj.id}`)
      .then((res) => {
        setInitPrice(parseInt(res.data.result.price));
      })
      .catch((err) => console.log(err));
  }, []);

  const closeProduct = () => {
    cart.setCart(cart.cart.filter((v, i) => v.index !== obj.index));
  };

  useEffect(() => {
    cart.cart[id].amount = amount;
  }, [amount]);

  useEffect(() => {
    cart.cart[id].price = price;
  }, [price]);

  const incAmount = () => {
    setAmount(amount + 1);
    setPrice(price + initPrice);
  };

  const decAmount = () => {
    setAmount(amount - 1);
    setPrice(price - initPrice);
  };


  const classes = createStyles(theme => ({
    root: {
      display: 'flex',
      padding: theme.spacing(3),
    },
    image: {
      width: '100%',
      height: 0,
    },
    info: {
      marginLeft: theme.spacing(3),
    },
    name: {
      fontSize: '1.5rem',
    },
    price: {
      fontSize: '0.875rem',
    },
    actions: {
      display: 'flex',
      alignItems: 'center',
      marginTop: theme.spacing(3),
    },
    actionButton: {
      padding: theme.spacing(1),
      fontSize: '0.875rem',
      backgroundColor: theme.colors.theme,
      color: theme.white,
    },
    closeButton: {
      margin: theme.spacing(2),
      marginLeft: theme.spacing(3),
      width: 20,
      height: 20,
    },
  }));

  return (
    <div className={classes.root}>
      <Image src={obj.imageUrl} className={classes.image} alt="Loading...." />
      <div className={classes.info}>
        <p className={classes.name}>{obj.name}</p>
        <p className={classes.price}>{price}$</p>
        <div className={classes.actions}>
          <Button className={classes.actionButton} onClick={incAmount}>
            +
          </Button>
          <p className={classes.actions}>{amount}</p>
          <Button
            className={classes.actionButton}
            onClick={(e) => (amount > 1 ? decAmount() : null)}
          >
            -
          </Button>
        </div>
      </div>
      <FaTimes className={classes.closeButton} onClick={closeProduct} />
    </div>
  );
};

export default Cartmini;
