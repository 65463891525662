import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import {
  createStyles,
  Title,
  SimpleGrid,
  Text,
  Grid,
  Col,
  rem,
  Paper,
  Button,
} from "@mantine/core";
import { VideoCardGrid } from "../components/Cards/VideoCard";
import { AuthContext } from "../hooks/AuthContext";

const useStyles = createStyles((theme) => ({
  wrapper: {
    padding: `calc(${theme.spacing.xl} * 1) ${theme.spacing.xl}`,
    margin: `calc(${theme.spacing.xl} * 1) ${theme.spacing.xl}`,
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontSize: rem(36),
    fontWeight: 900,
    lineHeight: 1.1,
    marginBottom: theme.spacing.sm,
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
  },

  buttonWrapper: {
    marginBottom: theme.spacing.lg,
    display: "flex",
    justifyContent: "center",
  },

  button: {
    marginRight: theme.spacing.md,
  },
}));

// const mockdata = [
//   {
//     id: 1,
//     title: "Video 1",
//     description: "Description of Video 1",
//     thumbnail: "https://example.com/thumbnail1.jpg",
//     link: "https://example.com/video1.mp4",
//     chapters: [
//       {
//         title: "Chapter 1",
//         start: 0,
//       },
//       {
//         title: "Chapter 2",
//         start: 60,
//       },
//       {
//         title: "Chapter 3",
//         start: 120,
//       },
//     ],
//   },
//   {
//     id: 10,
//     title: "Video 2",
//     description: "Description of Video 2",
//     thumbnail: "https://example.com/thumbnail2.jpg",
//     link: "https://example.com/video2.mp4",
//     chapters: [
//       {
//         title: "Chapter 1",
//         start: 0,
//       },
//       {
//         title: "Chapter 2",
//         start: 45,
//       },
//       {
//         title: "Chapter 3",
//         start: 90,
//       },
//     ],
//   },
//   // Add more video objects as needed
// ];

export function Videos() {
  const { classes } = useStyles();
  const [activePage, setActivePage] = useState("public");
  const { user } = useContext(AuthContext);
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/video/`
        );
        const allVideos = response?.data.videos;

        if (activePage === "public") {
          const publicVideos = allVideos.filter(
            (video) => video.privacy === "public"
          );
          setVideos(publicVideos);
        } else if (activePage === "private" && user) {
          // Fetch all users
          const usersResponse = await axios.get(
            `${process.env.REACT_APP_API_URL}/api/user/`
          );
          const allUsers = usersResponse.data.response.data;

          // Find the user based on the user ID from the AuthContext
          const currentUser = allUsers.find((u) => u.id === user.id);

          if (currentUser) {
            const privateVideoIds = currentUser.videos?.map(
              (video) => video.id
            );

            // Filter private videos based on the user's video IDs
            const privateVideos = allVideos.filter(
              (video) =>
                video.privacy === "private" &&
                privateVideoIds.includes(video.id)
            );
            setVideos(privateVideos);
          }
        } else {
          setVideos(allVideos);
        }
      } catch (error) {
        console.error("Error fetching Videos:", error);
      }
    };

    fetchVideos();
  }, [activePage, user]);

  const handleButtonClick = (page) => {
    setActivePage(page);
  };

  let pageTitle = "PUBLIC VIDEOS";
  let pageDesc =
    "Explore our collection of public videos showcasing our work. Gain valuable insights and stay informed about our latest projects. Watch anytime and anywhere at your convenience. Discover the expertise and excellence that define our organization.";

  if (activePage === "private") {
    pageTitle = "PRIVATE VIDEOS";
    pageDesc =
      "Access our exclusive collection of private videos, available only to authorized members. Dive deeper into our behind-the-scenes footage, confidential projects, and exclusive content. Stay connected with our organization's confidential updates and insider knowledge.";
  } else if (activePage === "categories") {
    pageTitle = "CATEGORIES";
    pageDesc =
      "Discover our extensive range of video categories, carefully curated to cater to your interests and preferences. Explore diverse topics and themes that span various industries and disciplines. From educational content to entertainment, find the videos that captivate and inspire you.";
  }

  return (
    <div className={classes.wrapper}>
      <Grid gutter={20}>
        <Col span={12}>
          <Title className={classes.title}>{pageTitle}</Title>
          <Text c="dimmed">{pageDesc}</Text>
        </Col>
        <Col span={12}>
          <div className={classes.buttonWrapper}>
            <Button
              className={classes.button}
              variant={activePage === "public" ? "filled" : "outline"}
              color="blue"
              onClick={() => handleButtonClick("public")}
            >
              Public
            </Button>
            {user && (
              <>
                <Button
                  className={classes.button}
                  variant={activePage === "private" ? "filled" : "outline"}
                  color="blue"
                  onClick={() => handleButtonClick("private")}
                >
                  Private
                </Button>
                <Button
                  className={classes.button}
                  variant={activePage === "categories" ? "filled" : "outline"}
                  color="blue"
                  onClick={() => handleButtonClick("categories")}
                >
                  Categories
                </Button>
              </>
            )}
          </div>
          <SimpleGrid>
            <Paper>
              {activePage === "public" && <VideoCardGrid mockdata={videos} />}
              {/* Render private page or categories page based on the activePage state */}
              {activePage === "private" && <VideoCardGrid mockdata={videos} />}
              {/* {activePage === "categories" && <CategoriesPage />} */}
            </Paper>
          </SimpleGrid>
        </Col>
      </Grid>
    </div>
  );
}
